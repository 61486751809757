import React from "react";
import { useParams } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";

import TicketsManager from "../../components/TicketsManager/";
import Ticket from "../../components/Ticket/";
import { i18n } from "../../translate/i18n";
import Hidden from "@material-ui/core/Hidden";

import noTicketSelected from "../../assets/noTicketSelected.png";

const useStyles = makeStyles((theme) => ({
  chatContainer: {
    flex: 1,
    height: "100%",
    overflowY: "hidden",
  },
  chatPapper: {
    display: "flex",
    height: "100%",
  },
  contactsWrapper: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    overflowY: "hidden",
  },
  contactsWrapperSmall: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    overflowY: "hidden",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  messagessWrapper: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    maxHeight: "100%",
    overflowY: "auto",
    color: "#56647E",
  },
  welcomeMsg: {
    position: "relative",
    backgroundColor: "#FFF",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    height: "100%",
    textAlign: "center",
    color: "#56647E",
    fontFamily: '"Rubik", serif',
    borderRadius: 0,
  },
  title: {
    fontWeight: 500,
    margin: "-1px 0",
    fontSize: '1.2rem'
  },
  subTitle: {
    margin: "10px 0",
    fontSize: '0.879rem'
  },
  ticketsManager: {},
  ticketsManagerClosed: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

const Chat = () => {
  const classes = useStyles();
  const { ticketId } = useParams();

  return (
    <div className={classes.chatContainer}>
      <Grid container className={classes.chatPapper} spacing={0}>
        <Grid
          item
          className={
            ticketId ? classes.contactsWrapperSmall : classes.contactsWrapper
          }
          style={{ flexBasis: "490px", flexShrink: 0 }}
        >
          <TicketsManager />
        </Grid>
        <Grid item className={classes.messagessWrapper}>
          {ticketId ? (
            <Ticket />
          ) : (
            <Hidden only={["sm", "xs"]}>
              <Paper className={classes.welcomeMsg}>
                <div style={{ textAlign: "center" }}>
                  <img
                    src={noTicketSelected}
                    alt="noTicketSelected"
                    style={{ width: "200px", marginBottom: "20px" }}
                  />
                  <p className={classes.title}>
                    {i18n.t("chat.noTicketMessageTitle", {
                      skipCapitalization: true,
                    })}
                  </p>
                  <span className={classes.subTitle}>
                    {i18n.t("chat.noTicketMessageSubTitle", {
                      skipCapitalization: true,
                    })}
                  </span>
                </div>
              </Paper>
            </Hidden>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default Chat;
