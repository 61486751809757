import React, { useEffect } from "react";
import toastError from "../../errors/toastError";
import { Button, Divider, Typography } from "@material-ui/core";
import messageListStyles from "../MessagesList/css/messageListStyles";


import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import mapFallback from "../../assets/mapFallback.png";

const LocationPreview = ({
  image,
  link,
  description,
  latitude,
  longitude,
  name,
  address
}) => {
  const classes = messageListStyles();

  useEffect(() => { }, [image, link, description, latitude, longitude]);

  const handleLocation = async () => {
    try {
      window.open(link);
    } catch (err) {
      toastError(err);
    }
  };
  
  return (
    <div className={classes.locationPreviewWrapper}>
      <div className={classes.mapImageContainer}>
        <img
          src={mapFallback}
          alt="Map preview"
          onClick={handleLocation}
          className={classes.googleMapPreview}
        />
      </div>

      <div className={classes.mapDescription}>
        {name && (
          <Typography variant="subtitle1" className={classes.locationName}>
            {name}
          </Typography>
        )}
        {address && (
          <Typography variant="body2" className={classes.locationAddress}>
            {address}
          </Typography>
        )}
      </div>
        <Divider className={classes.locationDivider} />

      <Button
        fullWidth
        onClick={handleLocation}
        disabled={!link}
        className={classes.locationButton}
      >
        <FontAwesomeIcon icon={faExternalLinkAlt} style={{ marginRight: 8 }} />
        Visualizar
      </Button>
    </div>
  );


};

export default LocationPreview;
