import { makeStyles } from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import whatsBackground from "../../../assets/wa-background.png"

const messageListStyles = makeStyles((theme) => ({
  messagesListWrapper: {
    overflow: "hidden",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },

  messageCenter: {
    marginTop: 5,
    alignItems: "center",
    verticalAlign: "center",
    alignContent: "center",
    backgroundColor: "#E1F5FEEB",
    fontSize: "12px",
    minWidth: 100,
    maxWidth: 270,
    color: "#272727",
    borderTopLeftRadius: 0,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 5,
    paddingBottom: 0,
    boxShadow: "0 1px 1px #b3b3b3",
  },

  messagesList: {
    backgroundImage: `url(${whatsBackground})`,
    backgroundColor: "#EFEAE2",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    padding: "20px 20px 20px 20px",
    overflowY: "scroll",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "90px",
    },
    ...theme.scrollbarStyles,
  },


  circleLoading: {
    color: green[500],
    position: "absolute",
    opacity: "70%",
    top: 0,
    left: "50%",
    marginTop: 12,
    width: "20px !important",
    height: "20px !important",
  },

  messageLeft: {
    marginRight: 20,
    marginTop: 2,
    minWidth: 100,
    maxWidth: 265,
    height: "auto",
    display: "block",
    position: "relative",
    "&:hover #messageActionsButton": {
      display: "flex",
      position: "absolute",
      top: 0,
      right: 0,
    },

    whiteSpace: "pre-wrap",
    backgroundColor: "#ffffff",
    color: "#303030",
    alignSelf: "flex-start",
    borderTopLeftRadius: 0,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 5,
    paddingBottom: 0,
    boxShadow: "0 1px 1px #b3b3b3",
  },

  messageLeftError: {
    marginRight: 20,
    marginTop: 2,
    minWidth: 100,
    maxWidth: 600,
    height: "auto",
    display: "block",
    position: "relative",
    "&:hover #messageActionsButton": {
      display: "flex",
      position: "absolute",
      top: 0,
      right: 0,
    },

    whiteSpace: "pre-wrap",
    backgroundColor: "#ffcccc",
    color: "#303030",
    alignSelf: "flex-start",
    borderTopLeftRadius: 0,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 5,
    paddingBottom: 0,
    boxShadow: "0 1px 1px #b3b3b3",
    fontWeight: "bold",
    fontStyle: "italic",
    height: '39px'
  },

  quotedContainerLeft: {
    margin: "-3px -6px 6px -6px",
    overflow: "hidden",
    backgroundColor: "#f0f0f0",
    borderRadius: "7.5px",
    display: "flex",
    position: "relative",
  },

  quotedMsg: {
    padding: 10,
    maxWidth: 300,
    height: "auto",
    display: "block",
    whiteSpace: "pre-wrap",
    overflow: "hidden",
  },

  quotedSideColorLeft: {
    flex: "none",
    width: "4px",
    backgroundColor: "#6bcbef",
  },

  messageRight: {
    marginLeft: 20,
    marginTop: 2,
    minWidth: 100,
    maxWidth: 265,
    height: "auto",
    display: "block",
    position: "relative",
    "&:hover #messageActionsButton": {
      display: "flex",
      position: "absolute",
      top: 0,
      right: 0,
    },

    whiteSpace: "pre-wrap",
    backgroundColor: "#dcf8c6",
    color: "#303030",
    alignSelf: "flex-end",
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 0,
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 5,
    paddingBottom: 0,
    boxShadow: "0 1px 1px #b3b3b3",
  },

  messageRightError: {
    marginLeft: 20,
    marginTop: 2,
    minWidth: 100,
    maxWidth: 600,
    height: "auto",
    display: "block",
    position: "relative",
    "&:hover #messageActionsButton": {
      display: "flex",
      position: "absolute",
      top: 0,
      right: 0,
    },

    whiteSpace: "pre-wrap",
    backgroundColor: "#ffcccc",
    color: "#303030",
    alignSelf: "flex-end",
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 0,
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 5,
    paddingBottom: 0,
    boxShadow: "0 1px 1px #b3b3b3",
  },

  quotedContainerRight: {
    margin: "-3px -6px 6px -6px",
    overflowY: "hidden",
    backgroundColor: "#cfe9ba",
    borderRadius: "7.5px",
    display: "flex",
    position: "relative",
  },

  quotedMsgRight: {
    padding: 10,
    maxWidth: 300,
    height: "auto",
    whiteSpace: "pre-wrap",
  },

  quotedSideColorRight: {
    flex: "none",
    width: "4px",
    backgroundColor: "#35cd96",
  },

  messageActionsButton: {
    display: "none",
    position: "relative",
    color: "#999",
    zIndex: 1,
    backgroundColor: "inherit",
    opacity: "90%",
    "&:hover, &.Mui-focusVisible": { backgroundColor: "inherit" },
  },

  messageContactName: {
    display: "flex",
    color: "#6bcbef",
    fontWeight: 500,
  },

  textContentItem: {
    overflowWrap: "break-word",
    padding: "3px 6px 6px 6px",
    fontFamily: '"Roboto", "San Francisco", sans-serif !important',
  },

  textContentItemDeleted: {
    fontStyle: "italic",
    color: "rgba(0, 0, 0, 0.36)",
    overflowWrap: "break-word",
    padding: "3px 80px 6px 6px",
  },

  messageMedia: {
    objectFit: "cover",
    width: 250,
    height: 200,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },

  timestamp: {
    fontSize: 11,
    position: "absolute",
    bottom: 0,
    right: 5,
    color: "#999",
  },

  dailyTimestamp: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    alignSelf: "center",
    width: "80px",
    backgroundColor: "#fdfffa",
    margin: "10px auto",
    borderRadius: "10px",
    boxShadow: "0 1px 1px #b3b3b3",
    position: "sticky",
    top: 0,
    zIndex: 1000,
  },

  dailyTimestampText: {
    fontSize: "13px",
    letterSpacing: "0px",
    color: "#54656F",
    padding: 8,
    alignSelf: "center",
    marginLeft: "0px",
    textTransform: "uppercase"
  },

  ackIcons: {
    fontSize: 18,
    verticalAlign: "middle",
    marginLeft: 4,
  },

  deletedIcon: {
    fontSize: 18,
    verticalAlign: "middle",
    marginRight: 4,
  },

  ackDoneAllIcon: {
    color: "#56BEEB",
    fontSize: 18,
    verticalAlign: "middle",
    marginLeft: 4,
  },

  downloadMedia: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "inherit",
    padding: 10,
  },

  containerDownloadButton: {
    textAlign: "center",
    margin: "6px 0px 5px 0px",
  },

  iframe: {
    display: "block",
    width: "298px",
    border: "none",
    loading: "lazy",
    pointerEvents: "none",
  },

  loadingMessage: {
    display: 'inline-block',
    position: 'relative',
    left: '30px',
    top: '10px',
    padding: '30px',
  },

  errorMessage: {
    backgroundColor: "#ffe6e6",
  },

  boxTimestamp: {
    padding: "0px 0px 2px 5px",
    marginLeft: "30px",
  },

  messageAck: {
    padding: "0px 0px 2px 5px",
    marginLeft: "30px",
  },

  liveLocationCard: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    borderRadius: 8,
    maxWidth: 300,
    position: "relative",
    marginTop: 5,
    marginBottom: 5,
  },

  liveLocationIcon: {
    color: "#FFA8A8",
    marginRight: 7,
    fontSize: 24,
    marginTop: 24,
  },

  liveLocationTitle: {
    margin: 0,
    padding: 0,
    fontSize: 14,
    fontWeight: 600,
    color: "#777777",
    marginBottom: 4,
  },

  liveLocationText: {
    margin: 0,
    padding: 0,
    fontSize: 12,
    color: "#777",
    lineHeight: 1.4,
    fontStyle: "italic",
  },

  liveLocationTime: {
    position: "absolute",
    right: 10,
    bottom: 4,
    fontSize: 11,
    color: "#999",
  },

  googleMapPreview: {
    width: "250px",
    height: "150px",
    borderRadius: "8px",
    objectFit: "cover",
    cursor: "pointer",
    boxShadow: "0 1px 1px #b3b3b3",
    transition: "opacity 0.2s",
    "&:hover": {
      opacity: 0.9,
    },
  },

  locationIcon: {
    color: "#f44336",
  },
  mapDescription: {
    padding: "0px 8px",
    maxWidth: '248px'
  },
  locationName: {
    fontWeight: 500,
    color: '#4F4F4F',
    fontSize: '15px'
  },
  locationAddress: {
    fontSize: '12px'
  },
  locationButton: {
    color: " #0077FF",
  },

  checkbox: {
    marginRight: "0px",
    width: "20px",
    height: "20px",
    borderRadius: "4px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    transition: "box-shadow 0.2s ease-in-out",
    cursor: "pointer",
    "&:hover": {
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    },
  },
  checkboxContainer: {
    paddingRight: "10px",
    display: "flex",
  },

  selectedHoverMessageFormMe: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    transition: "background-color 0.2s ease-in-out",
    cursor: (props) => (props.isForwardingMode ? "pointer" : "default"),
    backgroundColor: (props) =>
      props.isSelected ? "rgba(220, 226, 216, 0.5)" : "transparent",
    "&:hover": {
      backgroundColor: (props) =>
        props.isForwardingMode && !props.isSelected
          ? "rgba(220, 226, 216, 0.5)"
          : "transparent",
    },
    marginBottom: (props) => (props.isForwardingMode ? 4 : 0),
    borderRadius: (props) => (props.isForwardingMode ? 2 : 0),
  },

  selectedMessageFromMe: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    transition: "background-color 0.2s ease-in-out",
    cursor: (props) => (props.isForwardingMode ? "pointer" : "default"),
    backgroundColor: 'rgba(220, 226, 216, 0.5)',
    marginBottom: (props) => (props.isForwardingMode ? 4 : 0),
    borderRadius: (props) => (props.isForwardingMode ? 2 : 0),
  },

  msgClientHoverToSelected: {
    display: "flex",
    alignItems: "center",
    transition: "background-color 0.2s ease-in-out",
    cursor: (props) => (props.isForwardingMode ? "pointer" : "default"),
    backgroundColor: (props) =>
      props.isSelected ? "rgba(220, 226, 216, 0.5)" : "transparent",
    "&:hover": {
      backgroundColor: (props) =>
        props.isForwardingMode && !props.isSelected
          ? "rgba(220, 226, 216, 0.5)"
          : "transparent",
    },
    marginBottom: (props) => (props.isForwardingMode ? 4 : 0),
    borderRadius: (props) => (props.isForwardingMode ? 2 : 0),
  },

  msgClientSelected: {
    display: "flex",
    alignItems: "center",
    transition: "background-color 0.2s ease-in-out",
    cursor: (props) => (props.isForwardingMode ? "pointer" : "default"),
    backgroundColor: 'rgba(220, 226, 216, 0.5)',
    marginBottom: (props) => (props.isForwardingMode ? 4 : 0),
    borderRadius: (props) => (props.isForwardingMode ? 2 : 0),
  },


  


}));

export default messageListStyles;
