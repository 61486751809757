import React, { useState, useEffect, useRef } from "react";
import { useParams, useHistory } from "react-router-dom";

import { Paper, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import openSocket from "../../services/socket-io";

// Ícone de exemplo
import CloudUploadIcon from "@material-ui/icons/CloudUpload";

import MessageInput from "../MessageInput";
import MessagesList from "../MessagesList";
import TicketHeader from "../TicketHeader";
import TicketInfo from "../TicketInfo";
import TicketActionButtons from "../TicketActionButtons";
import ContactDrawer from "../ContactDrawer";
import { TagsContainer } from "../TagsContainer";
import { api } from "../../services/api";
import PubSub from "pubsub-js";
import toastError from "../../errors/toastError";
import { ReplyMessageProvider } from "../../context/ReplyingMessage/ReplyingMessageContext";
import MessageFowardInput from "../MessageFowardInput";
import ContactListModal from "../ContactListModal";

const drawerWidth = 320;

const useStyles = makeStyles((theme) => ({
  "@global": {
    "body.dragging": {
      cursor: "copy !important",
    },
  },
  root: {
    display: "flex",
    height: "100%",
    position: "relative",
    overflow: "hidden",
    background: "#F0F2F5",
    maxHeight: "100%",
    width: "100%",
  },
  mainWrapper: {
    flex: 1,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderLeft: "0",
    marginRight: -drawerWidth,
    background: "#F0F2F5",
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  mainWrapperShift: {
    marginRight: 0,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    background: "#F0F2F5",
  },
  messagesList: {
    flex: 1,
    overflowY: "auto",
  },

  dropOverlay: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "#222D33",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 9999,
    pointerEvents: "auto",
    opacity: "0.9",
  },

  dropContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    border: "dashed #939393",
    padding: "100px",
    borderRadius: "15px",
  },
  dropIcon: {
    fontSize: 60,
    marginBottom: 16,
    color: "#b7b7b7",
  },
  dropTitle: {
    fontSize: 24,
    color: "#fff",
    marginBottom: 8,
  },
  dropSubtitle: {
    fontSize: 16,
    color: "#b7b7b7",
  },
}));

function Ticket() {
  const classes = useStyles();
  const history = useHistory();
  const { ticketId } = useParams();

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [ticket, setTicket] = useState({});
  const [contact, setContact] = useState({});
  const [showTags, setShowTags] = useState(false);
  const [messagesUpdated, setMessagesUpdated] = useState(false);
  const [isForwardingMode, setIsForwardingMode] = useState(false);
  const [quantityMessageSelected, setQuantityMessageSelected] = useState(0);
  const [isContactListModalOpen, setIsContactListModalOpen] = useState(false);
  const [selectedMessages, setSelectedMessages] = useState([]);

  // Estado para mostrar a overlay de arraste
  const [overlayVisible, setOverlayVisible] = useState(false);
  // Armazena todos os arquivos "dropados" até agora
  const [droppedFiles, setDroppedFiles] = useState([]);

  const rootRef = useRef(null);

  // Carrega ticket
  const fetchTicket = async () => {
    try {
      setLoading(true);
      const { data } = await api.get(`/tickets/${ticketId}`);
      setTicket(data);
      setContact(data.contact);
    } catch (err) {
      toastError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTicket();
    const token = PubSub.subscribe("USER_ID_TRANSFER", () => {
      fetchTicket();
    });
    return () => {
      PubSub.unsubscribe(token);
    };
  }, [ticketId]);

  // Socket.io
  useEffect(() => {
    const socket = openSocket();

    socket.on("connect", () => socket.emit("joinChatBox", ticketId));

    socket.on(`ticket-${ticket?.idGraf}`, (data) => {
      if (data.action === "update") {
        setTicket(data.ticket);
      } else if (data.action === "delete") {
        history.push("/tickets");
      }
    });

    socket.on("contact", (data) => {
      if (data.action === "update") {
        setContact((prev) => {
          if (prev.id === data.contact?.id) {
            return { ...prev, ...data.contact };
          }
          return prev;
        });
      }
    });

    return () => socket.disconnect();
  }, [ticketId, ticket?.idGraf, history]);

  // Ao mudar de ticket, limpa overlay e reset de arquivos
  useEffect(() => {
    setOverlayVisible(false);
    setDroppedFiles([]);
    document.body.classList.remove("dragging");
  }, [ticketId]);

  // Ativa cursor no body quando overlay aparece
  const onOverlayMounted = () => {
    setTimeout(() => {
      document.body.classList.add("dragging");
    }, 0);
  };

  // DRAG & DROP
  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.dataTransfer.effectAllowed = "copy";
    e.dataTransfer.dropEffect = "copy";

    setOverlayVisible(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();

    // Se o mouse saiu do root
    if (!rootRef.current.contains(e.relatedTarget)) {
      setOverlayVisible(false);
      document.body.classList.remove("dragging");
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.dataTransfer.effectAllowed = "copy";
    e.dataTransfer.dropEffect = "copy";
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setOverlayVisible(false);
    document.body.classList.remove("dragging");

    const files = e.dataTransfer.files;
    if (files && files.length > 0) {
      setDroppedFiles((prev) => [...prev, ...Array.from(files)]);
      e.dataTransfer.clearData();
    }
  };

  // Remove overlay se sair da janela
  useEffect(() => {
    function handleWindowDragLeave(event) {
      if (
        event.clientX <= 0 ||
        event.clientY <= 0 ||
        event.clientX >= window.innerWidth ||
        event.clientY >= window.innerHeight
      ) {
        setOverlayVisible(false);
        document.body.classList.remove("dragging");
      }
    }
    function handleWindowDrop() {
      setOverlayVisible(false);
      document.body.classList.remove("dragging");
    }

    window.addEventListener("dragleave", handleWindowDragLeave);
    window.addEventListener("drop", handleWindowDrop);

    return () => {
      window.removeEventListener("dragleave", handleWindowDragLeave);
      window.removeEventListener("drop", handleWindowDrop);
    };
  }, []);

  // Outras funções
  const handleOverlayMouseEnter = () => {};
  const handleOverlayMouseLeave = () => {};

  const handleDrawerOpen = () => setDrawerOpen(true);
  const handleDrawerClose = () => setDrawerOpen(false);
  const handleToggleTags = () => setShowTags((v) => !v);
  const handleForwardingModeChange = (mode) => setIsForwardingMode(mode);
  const handleQuantitySelected = (qt) => setQuantityMessageSelected(qt);
  const handleCloseForwardingMode = () => setIsForwardingMode(false);
  const handleOpenContactListModal = () => setIsContactListModalOpen(true);
  const handleCloseContactListModal = () => setIsContactListModalOpen(false);
  const handleSelectedMessagesChange = (msgs) => setSelectedMessages(msgs);
  const resetMessagesUpdated = () => setMessagesUpdated(false);

  // Para limpar do pai, se o filho solicitar
  const handleClearDroppedFiles = () => {
    setDroppedFiles([]);
  };

  return (
    <div
      ref={rootRef}
      className={classes.root}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      <Paper
        variant="outlined"
        elevation={0}
        className={clsx(classes.mainWrapper, {
          [classes.mainWrapperShift]: drawerOpen,
        })}
      >
        <TicketHeader loading={loading}>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <div style={{ maxWidth: "50%", flexBasis: "50%" }}>
              <TicketInfo
                contact={contact}
                ticket={ticket}
                onClick={handleDrawerOpen}
              />
            </div>
            <div
              style={{
                maxWidth: "50%",
                flexBasis: "50%",
                display: "flex",
              }}
            >
              <TicketActionButtons
                ticket={ticket}
                onToggleTags={handleToggleTags}
              />
            </div>
          </div>
        </TicketHeader>

        {showTags && (
          <Paper>
            <TagsContainer ticket={ticket} />
          </Paper>
        )}

        <ReplyMessageProvider>
          <MessagesList
            className={classes.messagesList}
            ticketId={ticketId}
            isGroup={ticket.isGroup}
            setMessagesUpdated={setMessagesUpdated}
            onForwardingModeChange={handleForwardingModeChange}
            quantityMessageSelected={handleQuantitySelected}
            isForwardingMode={isForwardingMode}
            onSelectedMessagesChange={handleSelectedMessagesChange}
          />

          {isForwardingMode ? (
            <MessageFowardInput
              quantityMessageSelected={quantityMessageSelected}
              onClose={handleCloseForwardingMode}
              onForward={handleOpenContactListModal}
            />
          ) : (
            <MessageInput
              ticketStatus={ticket.status}
              messageStatus={messagesUpdated}
              onResetMessageStatus={resetMessagesUpdated}
              droppedFiles={droppedFiles}
              onClearDroppedFiles={handleClearDroppedFiles}
            />
          )}

          {isContactListModalOpen && (
            <ContactListModal onClose={handleCloseContactListModal} />
          )}
        </ReplyMessageProvider>
      </Paper>

      {overlayVisible && (
        <div
          className={classes.dropOverlay}
          ref={(node) => {
            if (node) onOverlayMounted();
          }}
          onMouseEnter={handleOverlayMouseEnter}
          onMouseLeave={handleOverlayMouseLeave}
        >
          <div className={classes.dropContent}>
            <CloudUploadIcon className={classes.dropIcon} />
            <h2 className={classes.dropTitle}>Arraste o arquivo aqui</h2>
            <p className={classes.dropSubtitle}>
              Solte para anexar imediatamente ao chat
            </p>
          </div>
        </div>
      )}

      <ContactDrawer
        open={drawerOpen}
        handleDrawerClose={handleDrawerClose}
        contact={contact}
        loading={loading}
      />
    </div>
  );
}

export default Ticket;
