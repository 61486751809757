

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import useS3 from "../../hooks/useS3";
import { renderMessageAck } from "../../utils/renderMessagesAck";
import messageListStyles from "../MessagesList/css/messageListStyles";


const useStyles = makeStyles((theme) => ({
  videoContainer: {
    position: 'relative',
    width: 250,
    height: 200,
    borderRadius: 8,
    overflow: 'hidden',
    backgroundColor: '#000',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    marginBottom: '10px'
  },
  playButton: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    fontSize: "2em",
    color: "white",
    backgroundColor: "rgba(0, 0, 0, 0.9)",
    borderRadius: "50%",
    width: "64px",
    height: "64px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    border: "none",
  },
  downloadButton: {
    position: 'absolute',
    bottom: theme.spacing(1),
    right: theme.spacing(1),
    backgroundColor: "#D9F1FF",
    color: "#007bff",
    border: "none",
    borderRadius: "50%",
    width: 40,
    height: 40,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
    cursor: "pointer",
    zIndex: 2,
    transition: 'background-color 0.3s, color 0.3s',
    '&:hover': {
      backgroundColor: "#b0dfff",
      color: "#0056b3",
    },
  },
  loadingContainer: {
    width: 250,
    height: 200,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 8,
    backgroundColor: '#f0f0f0',
  },
  errorContainer: {
    width: 250,
    height: 200,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 8,
    backgroundColor: '#ffe6e6',
    color: '#ff0000',
  },
  containerAck: {
    position: 'absolute',
    left: '231px',
    color: '#999999'
  },
}));

const MessageVideo = ({ videoUrl, openModal, message }) => {
  const classes = useStyles();
  const messageStyles = messageListStyles();
  const { loading, error } = useS3(videoUrl, 'video');


  if (loading) {
    return (
      <div className={classes.loadingContainer}>
        <span>Carregando...</span>
      </div>
    );
  }

  if (error) {
    return (
      <div className={classes.errorContainer}>
        <span>Erro ao carregar vídeo.</span>
      </div>
    );
  }

  return (
    <>
      <div className={classes.videoContainer} onClick={() => openModal(videoUrl, 'video')}>
        <button className={classes.playButton} aria-label="Reproduzir vídeo">
          ▶
        </button>
      </div>
    </>
  );
};

export default MessageVideo;
