import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import useS3 from "../../hooks/useS3";


const useStyles = makeStyles((theme) => ({
  dialogContent: {
    position: 'relative',
    padding: 0,
    backgroundColor: 'black',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    width: '100vw',
    overflow: 'hidden',
  },
  media: {
    maxWidth: '80vw',
    maxHeight: '80vh',
    display: 'block',
    margin: 'auto',
    borderRadius: 8,
    boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
    objectFit: 'contain',
  },
  video: {
    width: '80vw',
    height: '80vh',
    borderRadius: 8,
    boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
    backgroundColor: 'black',
  },
  closeButton: {
    position: 'absolute',
    top: '16px',
    right: '25px',
    width: '40px',
    height: '40px',
    color: 'white',
    backgroundColor: 'rgba(0,0,0,1)',
    zIndex: 3,
  },
  arrowButton: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    color: 'white',
    zIndex: 3,
    backgroundColor: 'rgba(0,0,0,0.3)',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.5)',
    },
  },
  leftArrow: {
    left: theme.spacing(2),
  },
  rightArrow: {
    right: theme.spacing(2),
  },
  downloadButton: {
    position: 'absolute',
    top: theme.spacing(2),
    right: '80px',
    backgroundColor: "#D9F1FF",
    color: "#007bff",
    border: "none",
    borderRadius: "50%",
    width: 40,
    height: 40,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
    cursor: "pointer",
    zIndex: 3,
    transition: 'background-color 0.3s, color 0.3s',
    '&:hover': {
      backgroundColor: "#b0dfff",
    },
  },
}));

const ModalMedia = ({ open, onClose, mediaUrl, mediaType, onPrev, onNext }) => {
  const classes = useStyles();
  const { blobUrl, loading, error } = useS3(mediaUrl, mediaType);

  const handleKeyDown = (e) => {
    if (e.key === 'ArrowLeft') {
      onPrev();
    } else if (e.key === 'ArrowRight') {
      onNext();
    } else if (e.key === 'Escape') {
      onClose();
    }
  };

  useEffect(() => {
    if (open) {
      window.addEventListener('keydown', handleKeyDown);
    } else {
      window.removeEventListener('keydown', handleKeyDown);
    }
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [open]);

  if (!open) return null;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="false"
      fullWidth={true}
      fullScreen={true}
      classes={{ paper: classes.dialogContent }}
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
        },
      }}
    >

      <IconButton
        className={`${classes.arrowButton} ${classes.leftArrow}`}
        onClick={onPrev}
        aria-label="Imagem anterior"
      >
        <ArrowBackIosIcon />
      </IconButton>
      <IconButton
        className={`${classes.arrowButton} ${classes.rightArrow}`}
        onClick={onNext}
        aria-label="Próxima imagem"
      >
        <ArrowForwardIosIcon />
      </IconButton>


      <IconButton
        className={classes.closeButton}
        onClick={onClose}
        aria-label="Fechar modal"
      >
        <CloseIcon />
      </IconButton>

 
      {loading ? (
        <div style={{ color: 'white' }}>Carregando...</div>
      ) : error ? (
        <div style={{ color: 'white' }}>Erro ao carregar a mídia.</div>
      ) : mediaType === 'image' ? (
        <img src={blobUrl} alt="Modal" className={classes.media} />
      ) : mediaType === 'video' ? (
        <video src={blobUrl} className={classes.video} controls autoPlay />
      ) : null}
    </Dialog>
  );
};

export default ModalMedia;
