import React from "react";
import { GetApp } from "@material-ui/icons";
import { Button } from "@material-ui/core";
import otherStyle from "./css/otherStyle";
import useS3 from "../../hooks/useS3";

const OtherCors = ({ otherUrl }) => {
  const classes = otherStyle();
  const { blobUrl } = useS3(otherUrl)

  return (
    blobUrl ? (
      <>
        <div style={{ overflow: "hidden" }}>
          <div style={{ marginRight: "-17px" }}></div>
          <div className={classes.containerDownloadButton}>
            <Button
              startIcon={<GetApp />}
              color="primary"
              variant="outlined"
              target="_blank"
              border="none"
              href={blobUrl}
            >
              Download
            </Button>
          </div>
        </div>
      </>
    ) : null
  );
};

export default OtherCors;
