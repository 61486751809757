import React, { useState, useEffect, useRef } from "react";
import { useTheme } from "@material-ui/core/styles";
import {
	BarChart,
	CartesianGrid,
	Bar,
	XAxis,
	YAxis,
	ResponsiveContainer,
} from "recharts";
import { startOfHour, parseISO, format } from "date-fns";
import { i18n } from "../../translate/i18n";
import useTickets from "../../hooks/useTickets";

const Chart = () => {
	const theme = useTheme();
	const date = useRef(new Date().toISOString());
	const { tickets } = useTickets({ date: date.current, dashBoard: true});

	const [chartData, setChartData] = useState(() => {
		const hours = [];
		for (let i = 0; i < 24; i++) {
			hours.push({ time: `${i.toString().padStart(2, '0')}:00`, amount: 0 });
		}
		return hours;
	});

	useEffect(() => {
		setChartData(prevState => {
			
			let updatedChartData = prevState.map(dataPoint => ({
				...dataPoint,
				amount: 0 
			}));
	
	
			tickets.forEach(ticket => {
				const ticketTime = format(startOfHour(parseISO(ticket?.createdAt)), "HH:mm");
				const chartDataPoint = updatedChartData.find(point => point.time === ticketTime);
	
				if (chartDataPoint) {
					chartDataPoint.amount++;
				}
			});
	
			return updatedChartData;
		});
	}, [tickets]);

	return (
		<React.Fragment>
			<div>
				<h3>{`${i18n.t("dashboard.charts.perDay.title")}${tickets.length}`}</h3>
			</div>
			<ResponsiveContainer>
				<BarChart
					data={chartData}
					margin={{
						top: 16,
						right: 50,
						bottom: 0,
						left: 24,
					}}
				>
					<CartesianGrid strokeDasharray="3 3" />
					<XAxis dataKey="time" stroke={theme.palette.text.secondary} />
					<YAxis
						type="number"
						allowDecimals={false}
						stroke={theme.palette.text.secondary}
					>
					</YAxis>
					<Bar dataKey="amount" fill="#CEEEE"/>
				</BarChart>
			</ResponsiveContainer>
		</React.Fragment>
	);
};

export default Chart;

