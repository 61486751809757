import { api } from "../api";

const TicketService = {
    getTicketsByStatus: async (pageSize = 10, pageNumber = 1, status, selectedQueueIds , selectedTagsIds , visibility) => {
        try {
            const response = await api.get(`/listTicketsByStatus/${status}`, {
                params: {
                    pageSize,
                    pageNumber,
                    selectedQueueIds,
                    selectedTagsIds,
                    visibility
                },
            });
            return response.data;
        } catch (error) {
            console.error("Erro ao buscar tickets abertos:", error);
            throw error;
        }
    },

    getTicketById: async (ticketId) => {
        try {
            const response = await api.get(`/tickets/${ticketId}`);
            return response.data;
        } catch (error) {
            console.error("Erro ao buscar ticket:", error);
            throw error;
        }
    },

    updateTicket: async (ticketId, data) => {
        try {
            await api.put(`/tickets/${ticketId}`, data);
        } catch (error) {
            console.error("Erro ao atualizar o ticket:", error);
            throw error;
        }
    },

    organizeTickets: (ticketsToOrganize) => {

        if (ticketsToOrganize.length === 0) return [];

        const organizedTickets = [...ticketsToOrganize].sort((a, b) => {
            const dateA = a.lastMessageTime ? new Date(a.lastMessageTime) : null;
            const dateB = b.lastMessageTime ? new Date(b.lastMessageTime) : null;

            if (dateA && dateB) {
                return dateB - dateA;
            }

            if (!dateA && dateB) return 1;
            if (!dateB && dateA) return -1;

            return 0;
        });

        return organizedTickets;
    },


};

export default TicketService;
