import React from "react";
import CancelIcon from "@material-ui/icons/Cancel"; // Exemplo de ícone no Material UI
import messageListStyles from "../MessagesList/css/messageListStyles";

const LiveLocationCard = () => {
    const classes = messageListStyles();
    return (
        <div className={classes.liveLocationCard}>
            <div className={classes.liveLocationIcon}>
                <CancelIcon fontSize="large"/>
            </div>
            <div>
                <h4 className={classes.liveLocationTitle}>Localização em Tempo Real</h4>
                <p className={classes.liveLocationText}>
                    Não é possível exibir a localização em tempo real.
                    Abra o WhatsApp no seu celular para ver a mensagem.
                </p>
            </div>
        </div>
    );
};

export default LiveLocationCard;
