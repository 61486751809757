import React, { useEffect, useState } from "react";
import QRCode from "qrcode.react";
import toastError from "../../errors/toastError";

import { Dialog, DialogContent, Paper, Typography, CircularProgress } from "@material-ui/core";
import { i18n } from "../../translate/i18n";
import { api } from "../../services/api";
import connectToSocket from "../../services/socket-io";

const QrcodeModal = ({ open, onClose, whatsAppId }) => {
  const [qrCode, setQrCode] = useState("");
  const [loading, setLoading] = useState(false);
  const idGraf = localStorage.getItem("idGraf");

  useEffect(() => {
    const fetchSession = async () => {
      if (!whatsAppId) return;

      setLoading(true);
      try {
        const { data } = await api.get(`/whatsapp/${whatsAppId}`);
        
        if (data.qrcode && data.qrcode.length < 23648) {
          setQrCode(data.qrcode);
        } else {
          console.warn("QR Code muito grande, tente reduzir a informação.");
          setQrCode("");
        }
      } catch (err) {
        toastError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchSession();
  }, [whatsAppId]);

  useEffect(() => {
    if (!whatsAppId) return;
    const socket = connectToSocket();

    socket.on(`whatsappSession-${idGraf}`, (data) => {
      if (data.action === "update" && data.session.id === whatsAppId) {
        if (data.session.qrcode && data.session.qrcode.length < 23648) {
          setQrCode(data.session.qrcode);
        } else {
          console.warn("QR Code recebido é muito grande, ignorando...");
          setQrCode("");
        }
      }

      if (data.action === "update" && !data.session.qrcode) {
        onClose();
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [whatsAppId, onClose]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" scroll="paper">
      <DialogContent>
        <Paper elevation={0} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <Typography color="primary" gutterBottom>
            {i18n.t("qrCode.message")}
          </Typography>
          {loading ? (
            <CircularProgress size={48} />
          ) : qrCode ? (
            <QRCode value={qrCode} size={256} />
          ) : (
            <Typography color="secondary">QR Code indisponível</Typography>
          )}
        </Paper>
      </DialogContent>
    </Dialog>
  );
};

export default React.memo(QrcodeModal);
