// src/hooks/useS3.js

import { useState, useEffect } from "react";
import { api } from "../../services/api";

const useS3 = (mediaURL, type = "other", retryTrigger = 0) => {
  const [blobUrl, setBlobUrl] = useState("");
  const [thumbnailUrl, setThumbnailUrl] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    const fetchMedia = async () => {
      if (!mediaURL) {
        setLoading(false);
        return;
      }
      setLoading(true);
      setError(false);
      setErrorMsg("");

      try {
        const path = mediaURL.split("/");
        if (path.length < 4) {
          throw new Error("URL inválida");
        }

        const { data } = await api.get(`/messages/download-file/${path[2]}/${path[3]}`);
        setBlobUrl(data);

        if (type === "video") {
          const newFileName = path[3].replace(".mp4", ".jpg");
          const thumbnailPath = `/messages/download-file/${path[2]}/thumbnail_${newFileName}`;
          const { data: thumbnailData } = await api.get(thumbnailPath);
          setThumbnailUrl(thumbnailData);
        }
      } catch (err) {
        console.error("[useS3] Failed to fetch media:", err);
        setError(true);
        setErrorMsg(err?.message || "Erro desconhecido");
      } finally {
        setLoading(false);
      }
    };

    fetchMedia();
  }, [mediaURL, type, retryTrigger]);

  return { blobUrl, thumbnailUrl, loading, error, errorMsg };
};

export default useS3;
