import React, { useContext, useEffect, useRef, useState } from "react";
import Paper from "@material-ui/core/Paper";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Badge from "@material-ui/core/Badge";
import Divider from "@material-ui/core/Divider";

import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";
import PersonIcon from "@material-ui/icons/Person";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

import { TagsFilter } from "../TagsFilter";
import NewTicketModal from "../NewTicketModal";
import TicketsList from "../TicketsList";
import TabPanel from "../TabPanel";

import { i18n } from "../../translate/i18n";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../Can";
import TicketsQueueSelect from "../TicketsQueueSelect";
import {
  Grid,
  Box,
  IconButton,
  Tooltip,
} from "@material-ui/core";

import { ThemeProvider } from "@material-ui/core/styles";
import Icon from "../Icon";
import { api } from "../../services/api";

import { setGlobalTabOpen, tabGlobalOpen } from "../../globalState";
import PubSub from "pubsub-js";
import TicketListItemOpen from "../TicketListItemOpen";
import TicketListItemPending from "../TicketListItemPending";
import TicketListItemClosed from "../TicketListItemClosed";
import connectToSocket from "../../services/socket-io";

import { useStyles } from "./css/style";
import { CustomTabs, GreenTab, theme } from "./css/theme";

const TicketsManager = () => {
  const classes = useStyles();
  const { user } = useContext(AuthContext);


  const [searchParam, setSearchParam] = useState("");

  const [debouncedSearch, setDebouncedSearch] = useState("");

  const [tabOpen, setTabOpen] = useState("open");
  const [newTicketModalOpen, setNewTicketModalOpen] = useState(false);
  const searchInputRef = useRef();

  const [filterTickets, setFilterTickets] = useState(
    user.profile === "admin" || user.profile === "SUPERADMIN" || user.canSeeAllTickets ? "All" : "Me"
  );
  const [pendingCount, setPendingCount] = useState(0);

  const [selectedTags, setSelectedTags] = useState([]);
  const userQueueIds = user.queues.map((q) => q.id);
  const [selectedQueueIds, setSelectedQueueIds] = useState(userQueueIds || []);
  const [showTagsFilter, setShowTagsFilter] = useState(false);

  const [valueOpenCount, setValueOpenCount] = useState(0);
  const [valuePendingCount, setValuePendingCount] = useState(0);
  const [isWithoutQueueSelected, setIsWithoutQueueSelected] = useState(true);
  const idGraf = localStorage.getItem("idGraf");
  const [openQueueSelect, setOpenQueueSelect] = useState(false);

  const showAll =
    filterTickets === "All" &&
      (user.profile === "admin" || user.profile === "SUPERADMIN" || user.canSeeAllTickets)
      ? true
      : false;
  const userId = user.id;


  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedSearch(searchParam);
    }, 500);
    return () => clearTimeout(timer);
  }, [searchParam]);


  const fetchTicketsCount = async () => {
    try {
      const queueIdsQueryParam = selectedQueueIds.join(",");
      const { data } = await api.get(
        `/getCountTickets/${isWithoutQueueSelected}/${showAll}/${userId}?queueIds=${queueIdsQueryParam}`
      );
      setValueOpenCount(data.openTicketsCount);
      setValuePendingCount(data.pendingTicketsCount);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchTicketsCount();

    if (!isWithoutQueueSelected && selectedQueueIds.length === 0) {
      setPendingCount(0);
    }
  }, [filterTickets, idGraf, isWithoutQueueSelected, selectedQueueIds, user.id]);


  useEffect(() => {
    const eventButtonAction = PubSub.subscribe(
      "TICKET_ACTION_UPDATED",
      (msg, data) => {
        fetchTicketsCount();
        if (
          (data.newStatus === "open" || data.newStatus === "transferTicket") &&
          tabGlobalOpen === "pending" &&
          msg
        ) {
          setTabOpen("open");
        }
        if (data.newStatus === "transferTicketBySearchingBox") {
          setTabOpen("open");

          setSearchParam("");
          setDebouncedSearch("");
          if (searchInputRef.current) {
            searchInputRef.current.value = "";
          }
        }
      }
    );
    return () => {
      PubSub.unsubscribe(eventButtonAction);
    };
  }, []);


  useEffect(() => {
    const socket = connectToSocket();
    socket.on(`ticket-${idGraf}`, (data) => {
      if (data.action === "newTicketIncoming") {
        PubSub.publish("TICKET_ACTION_UPDATED", {
          newStatus: "newTicketIncoming",
          ticket: data.ticket,
        });
      }
    });
    socket.on(`ticket-updated-${idGraf}`, (data) => {
      if (data.action === "transferTicket") {
        fetchTicketsCount();
      }
    });
    return () => {
      socket.disconnect();
    };
  }, [idGraf]);

  const handleSearch = (e) => {
    setSearchParam(e.target.value.toLowerCase());
  };

  const handleSelectedTags = (selecteds) => {
    const tags = selecteds.map((t) => t.id);
    setSelectedTags(tags);
  };

  const handleChangeTabOpen = (e, newValue) => {
    setGlobalTabOpen(newValue);
    setTabOpen(newValue);
  };

  const handleButtonClick = () => {
    setShowTagsFilter((prevState) => !prevState);
  };


  const iconResolvedColor = tabOpen === "closed" ? "#00A825" : "#757575";
  const iconPendingColor =
    tabOpen === "pending" ? theme.palette.primary.main : "#656565";
  const iconOpenColor =
    tabOpen === "open" ? theme.palette.primary.main : "#656565";


  const isSearching = debouncedSearch.length > 0;

  return (
    <ThemeProvider theme={theme}>
      <Paper elevation={0} variant="outlined" className={classes.ticketsWrapper}>
        <NewTicketModal
          modalOpen={newTicketModalOpen}
          onClose={() => setNewTicketModalOpen(false)}
        />

        {/* Barra de busca */}
        <Grid container className={classes.inputGrid}>
          <Grid item xs>
            <div className={classes.serachInputWrapper}>
              <SearchIcon className={classes.searchIcon} />
              <InputBase
                className={classes.searchInput}
                inputRef={searchInputRef}
                placeholder={i18n.t("tickets.search.placeholder")}
                type="search"
                onChange={handleSearch}
              />
            </div>
          </Grid>
          <Grid item>
            <Tooltip
              arrow
              title={i18n.t("ticketsManager.buttons.newTicket")}
              placement="bottom"
            >
              <IconButton
                className={classes.customButton}
                variant="outlined"
                color="primary"
                onClick={() => setNewTicketModalOpen(true)}
              >
                <Icon type="plusMessage" w={21} h={21} />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>

        {/* Filtro: Me/All, Tags, Departamentos */}
        <Paper square elevation={0} className={classes.ticketOptionsBox}>
          <Grid container spacing={3} alignItems="center">
            {/* Filtro "Me" ou "All" */}
            <Grid item xs={4} md={4}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Can
                  role={user.profile}
                  perform="tickets-manager:showall"
                  yes={() => (
                    <>
                      <Tooltip
                        arrow
                        title={i18n.t("ticketsManager.filter.myTickets")}
                        placement="top"
                      >
                        <IconButton
                          color={filterTickets === "Me" ? "primary" : "default"}
                          onClick={() => setFilterTickets("Me")}
                        >
                          <PersonIcon />
                        </IconButton>
                      </Tooltip>
                      <Divider
                        orientation="vertical"
                        variant="middle"
                        style={{ marginLeft: 1, marginRight: 1 }}
                        flexItem
                      />
                      <Tooltip
                        arrow
                        title={i18n.t("ticketsManager.filter.allTickets")}
                        placement="top"
                      >
                        <IconButton
                          color={filterTickets === "All" ? "primary" : "default"}
                          disabled={
                            user.profile === "admin" || user.profile === "SUPERADMIN" ||  user.canSeeAllTickets
                              ? false
                              : true
                          }
                          onClick={() => setFilterTickets("All")}
                        >
                          <PeopleAltOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                      {user.profile === "admin" || user.profile === "SUPERADMIN" ||  user.canSeeAllTickets
                        ? null
                        : (
                          <Tooltip
                            arrow
                            title={i18n.t(
                              "ticketsManager.filter.disabledAllTickets"
                            )}
                            placement="bottom"
                          >
                            <InfoOutlinedIcon
                              style={{
                                fontSize: "small",
                                marginLeft: "-10px",
                                marginTop: "10px",
                                color: "grey",
                              }}
                            />
                          </Tooltip>
                        )}
                    </>
                  )}
                />
              </Box>
            </Grid>

            {/* Botão de Tags */}
            <Grid item xs={4} md={4}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Tooltip
                  arrow
                  title={i18n.t("mainDrawer.listItems.tags")}
                  placement="top"
                >
                  <IconButton
                    color={showTagsFilter ? "primary" : "default"}
                    onClick={handleButtonClick}
                  >
                    <LocalOfferIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Grid>

            {/* Filtro de Filas */}
            <Grid item xs={4} md={4}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Tooltip
                  arrow
                  title={i18n.t("mainDrawer.listItems.departments")}
                  placement="top"
                >
                  <IconButton
                    style={{ marginRight: "-150px" }}
                    onClick={() => setOpenQueueSelect((prev) => !prev)}
                  >
                    <Icon type="departments" />
                  </IconButton>
                </Tooltip>
                <TicketsQueueSelect
                  selectedQueueIds={selectedQueueIds}
                  userQueues={user?.queues}
                  onChange={(values) => setSelectedQueueIds(values)}
                  isWithoutQueueSelected={isWithoutQueueSelected}
                  onIsWithoutQueueSelectedChange={setIsWithoutQueueSelected}
                  open={openQueueSelect}
                  setOpen={setOpenQueueSelect}
                />
              </Box>
            </Grid>
          </Grid>
        </Paper>
        <TagsFilter onFiltered={handleSelectedTags} show={showTagsFilter} />

        {/* Se não está pesquisando, mostra a lista normal */}
        {!isSearching && (
          <TabPanel value={"open"} name="open" className={classes.ticketsWrapper}>
            <CustomTabs
              value={tabOpen}
              onChange={handleChangeTabOpen}
              textColor="primary"
              variant="fullWidth"
              isResolved={tabOpen === "closed"}
            >
              <Tab
                label={
                  <Grid container alignItems="center" justifyContent="center">
                    <Grid item>
                      <Icon
                        type="chatme"
                        mt={5}
                        fill={iconOpenColor}
                        style={{ marginRight: "4px" }}
                      />
                    </Grid>
                    <Grid item>
                      <span className={classes.customFontOpen}>
                        {i18n.t("ticketsList.assignedHeader")}
                      </span>
                      <Badge
                        style={{ marginBottom: "20px" }}
                        badgeContent={valueOpenCount}
                        color="primary"
                        className={classes.badgeOpen}
                      />
                    </Grid>
                  </Grid>
                }
                value="open"
              />
              <Tab
                label={
                  <Grid container alignItems="center" justifyContent="center">
                    <Grid item>
                      <Icon
                        type="timer"
                        w={21.179}
                        h={21.179}
                        fill={iconPendingColor}
                        mt={5}
                        mr={6}
                      />
                    </Grid>
                    <Grid item>
                      <span className={classes.customFontPending}>
                        {i18n.t("ticketsList.pendingHeader")}
                      </span>
                      <Badge
                        style={{ marginBottom: "15px" }}
                        badgeContent={valuePendingCount}
                        color="primary"
                        className={classes.badgePending}
                      />
                    </Grid>
                  </Grid>
                }
                value="pending"
              />
              <GreenTab
                label={
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-around"
                    spacing={10}
                  >
                    <Grid item>
                      <Icon
                        type="check"
                        w={30}
                        h={30}
                        mt={10}
                        fill={iconResolvedColor}
                      />
                    </Grid>
                    <Grid item>
                      <span className={classes.customFontResolved}>
                        {i18n.t("ticketsList.closedHeader")}
                      </span>
                    </Grid>
                  </Grid>
                }
                value="closed"
              />
            </CustomTabs>
            <Paper className={classes.ticketsWrapper}>
              {tabOpen === "open" && (
                <TicketListItemOpen
                  selectedQueueIds={selectedQueueIds}
                  selectedTagsIds={selectedTags}
                  visibility={filterTickets}
                />
              )}
              {tabOpen === "pending" && (
                <TicketListItemPending
                  selectedQueueIds={selectedQueueIds}
                  selectedTagsIds={selectedTags}
                  visibility={filterTickets}
                />
              )}
              {tabOpen === "closed" && (
                <TicketListItemClosed
                  selectedQueueIds={selectedQueueIds}
                  selectedTagsIds={selectedTags}
                  visibility={filterTickets}
                />
              )}
            </Paper>
          </TabPanel>
        )}

        {/* Se está pesquisando (debouncedSearch), mostra <TicketsList> */}
        {isSearching && (
          <TabPanel value={"open"} name="open" className={classes.ticketsWrapper}>
            <Tabs
              value={tabOpen}
              onChange={handleChangeTabOpen}
              textColor="primary"
              variant="fullWidth"
              isResolved={tabOpen === "closed"}
              className={classes.tabPanelHidden}
            >
            </Tabs>
            <TicketsList searchParam={debouncedSearch} />
          </TabPanel>
        )}
      </Paper>
    </ThemeProvider>
  );
};

export default TicketsManager;
