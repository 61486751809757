import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faImage,
  faMapMarkerAlt,
  faFile,
  faVideo,
  faMusic
} from "@fortawesome/free-solid-svg-icons";

/**
 * Limpa e formata a mensagem de acordo com seu tipo.
 * @param {string} message O texto da mensagem (ex: "Imagem", "Documento", "Áudio", "Vídeo" etc.)
 * @returns {JSX.Element} Um elemento JSX exibindo ícone e texto truncado, se aplicável.
 */
function cleanMessageVCard(message) {
  
  if (!message) return <br />;

  // Localização
  if (message === "Localização" || message === "Localização em Tempo Real") {
    return (
      <span>
        <FontAwesomeIcon icon={faMapMarkerAlt} style={{ marginRight: 5 }} />
        {message}
      </span>
    );
  }

  // Imagem
  if (message === "Imagem") {
    return (
      <span>
        <FontAwesomeIcon icon={faImage} style={{ marginRight: 5 }} />
        Imagem
      </span>
    );
  }

  // Documento
  if (message === "Documento") {
    return (
      <span>
        <FontAwesomeIcon icon={faFile} style={{ marginRight: 5 }} />
        Documento
      </span>
    );
  }

  // Vídeo
  if (message === "Vídeo") {
    return (
      <span>
        <FontAwesomeIcon icon={faVideo} style={{ marginRight: 5 }} />
        Vídeo
      </span>
    );
  }

  // Áudio
  if (message === "Áudio") {
    return (
      <span>
        <FontAwesomeIcon icon={faMusic} style={{ marginRight: 5 }} />
        Áudio
      </span>
    );
  }

  // Contato (vCard)
  if (message.includes("BEGIN:VCARD")) {
    return <span>Contato</span>;
  }

  // Truncagem se for muito longo
  if (message.length > 40) {
    return <span>{`${message.substring(0, 35)}...`}</span>;
  }

  // Caso contrário, exibe o texto normal
  return <span>{message}</span>;
}

export { cleanMessageVCard };
