import React, {
  useState,
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from "react";
import { toast } from "react-toastify";
import { format, parseISO } from "date-fns";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import {
  Button,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Table,
  TableHead,
  Paper,
  Tooltip,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import {
  Edit,
  CheckCircle,
  SignalCellularConnectedNoInternet2Bar,
  SignalCellularConnectedNoInternet0Bar,
  SignalCellular4Bar,
  CropFree,
  DeleteOutline,
} from "@material-ui/icons";

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";
import TableRowSkeleton from "../../components/TableRowSkeleton";

import { api } from "../../services/api";
import WhatsAppModal from "../../components/WhatsAppModal";
import ConfirmationModal from "../../components/ConfirmationModal";
import QrcodeModal from "../../components/QrcodeModal";
import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";
import connectToSocket from "../../services/socket-io";
import { AuthContext } from "../../context/Auth/AuthContext";
import NewQrCodeModal from "../../components/NewQrCodeModal";
import zapiDark from "../../assets/zapi-dark.png";

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  customTableCell: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: theme.typography.pxToRem(14),
    border: "1px solid #dadde9",
    maxWidth: 450,
  },
  tooltipPopper: {
    textAlign: "center",
  },
  buttonProgress: {
    color: green[500],
  },
}));

const CustomToolTip = ({ title, content, children }) => {
  const classes = useStyles();

  return (
    <Tooltip
      arrow
      classes={{
        tooltip: classes.tooltip,
        popper: classes.tooltipPopper,
      }}
      title={
        <React.Fragment>
          <Typography gutterBottom color="inherit">
            {title}
          </Typography>
          {content && <Typography>{content}</Typography>}
        </React.Fragment>
      }
    >
      {children}
    </Tooltip>
  );
};

const reducer = (state, action) => {
  switch (action.type) {
    case "LOAD_WHATSAPPS": {
      return [...action.payload];
    }
    case "UPDATE_WHATSAPPS": {
      const updated = action.payload;
      const idx = state.findIndex((item) => item.id === updated.id);

      if (idx !== -1) {
        state[idx] = updated;
        return [...state];
      } else {
        return [updated, ...state];
      }
    }
    case "UPDATE_SESSION": {
      const whatsApp = action.payload;
      const idx = state.findIndex((s) => s.id === whatsApp.id);

      if (idx !== -1) {
        state[idx] = {
          ...state[idx],
          ...whatsApp,
        };
        return [...state];
      }
      return state;
    }
    case "DELETE_WHATSAPPS": {
      const id = action.payload;
      return state.filter((w) => w.id !== id);
    }
    default:
      return state;
  }
};

const Connections = () => {
  const classes = useStyles();
  const idGraf = localStorage.getItem("idGraf");

  const [whatsApps, dispatch] = useReducer(reducer, []);
  const [whatsAppModalOpen, setWhatsAppModalOpen] = useState(false);

  const [qrModalOpen, setQrModalOpen] = useState(false);
  const [newQrModalOpen, setNewQrModalOpen] = useState(false);

  const [selectedWhatsApp, setSelectedWhatsApp] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  const [loading, setLoading] = useState(true);
  const [isLoadingQrCode, setIsLoadingQrCode] = useState(false);
  const [isLoadingDisconnect, setIsLoadingDisconnect] = useState(false);

  const confirmationModalInitialState = {
    action: "",
    title: "",
    message: "",
    whatsAppId: "",
    open: false,
  };
  const [confirmModalInfo, setConfirmModalInfo] = useState(
    confirmationModalInitialState
  );

  const fetchSession = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await api.get("/whatsapp");
      dispatch({ type: "LOAD_WHATSAPPS", payload: data });
    } catch (err) {
      toastError(err);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchSession();
  }, [fetchSession]);

  useEffect(() => {
    const socket = connectToSocket();

    socket.on(`whatsappSession-${idGraf}`, (data) => {
      if (data.action === "update") {
        dispatch({ type: "UPDATE_SESSION", payload: data.session });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [idGraf]);

  const handleStartWhatsAppSession = async (whatsAppId) => {
    try {
      const { data } = await api.post(`/whatsappsession/${whatsAppId}`);
      dispatch({ type: "UPDATE_WHATSAPPS", payload: data });
    } catch (err) {
      toastError(err);
    }
  };

  const handleRequestNewQrCode = async (whatsApp) => {
    try {
      setIsLoadingQrCode(true);
      let data;

      if (whatsApp.zapi && whatsApp.zapiStatus === "CANCELADO") {
        setIsLoadingQrCode(false);
        toastError("Instância vencida! Entre em contato com o suporte.");
        return;
      }
      if (whatsApp.zapi) {
        const response = await api.get(`/whatsapp/${whatsApp.id}/generate-qr`);
        data = response.data;
      } else {
        const response = await api.put(`/whatsappsession/${whatsApp.id}`);
        data = response.data;
      }
      if (data?.id) {
        dispatch({ type: "UPDATE_WHATSAPPS", payload: data });
      }
    } catch (err) {
      toastError(err);
    } finally {
      setIsLoadingQrCode(false);
    }
  };

  const handleSubmitConfirmationModal = async () => {
    const { action, whatsAppId } = confirmModalInfo;

    if (action === "disconnect") {
      setIsLoadingDisconnect(true);
      try {
        const whatsApp = whatsApps.find((w) => w.id === whatsAppId);
        if (!whatsApp.zapi) {
          await api.delete(`/whatsappsession/${whatsAppId}`);
        } else {
          await api.delete(`/whatsappsessionZapi/${whatsAppId}`);
        }

        dispatch({
          type: "UPDATE_SESSION",
          payload: { id: whatsAppId, status: "DISCONNECTED" },
        });
      } catch (err) {
        toastError(err);
      } finally {
        setIsLoadingDisconnect(false);
      }
    }

    if (action === "delete") {
      try {
        await api.delete(`/whatsapp/${whatsAppId}`);
        toast.success(i18n.t("connections.toasts.deleted"), {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        dispatch({ type: "DELETE_WHATSAPPS", payload: whatsAppId });
      } catch (err) {
        toastError(err);
      }
    }

    setConfirmModalInfo(confirmationModalInitialState);
  };

  const handleOpenConfirmationModal = (action, whatsAppId) => {
    if (action === "disconnect") {
      setConfirmModalInfo({
        action,
        title: i18n.t("connections.confirmationModal.disconnectTitle"),
        message: i18n.t("connections.confirmationModal.disconnectMessage"),
        whatsAppId,
      });
    }
    if (action === "delete") {
      setConfirmModalInfo({
        action,
        title: i18n.t("connections.confirmationModal.deleteTitle"),
        message: i18n.t("connections.confirmationModal.deleteMessage"),
        whatsAppId,
      });
    }
    setConfirmModalOpen(true);
  };

  const handleOpenWhatsAppModal = () => {
    setSelectedWhatsApp(null);
    setWhatsAppModalOpen(true);
  };

  const handleCloseWhatsAppModal = useCallback(() => {
    setWhatsAppModalOpen(false);
    setSelectedWhatsApp(null);
  }, []);

  const handleOpenQrModal = (whatsApp) => {
    setSelectedWhatsApp(whatsApp);
    if (whatsApp.zapi) {
      setNewQrModalOpen(true);
    } else {
      setQrModalOpen(true);
    }
  };

  const handleCloseQrModal = useCallback(() => {
    setSelectedWhatsApp(null);
    setQrModalOpen(false);
    setNewQrModalOpen(false);
  }, []);

  const handleEditWhatsApp = (whatsApp) => {
    setSelectedWhatsApp(whatsApp);
    setWhatsAppModalOpen(true);
  };

  function formatPhoneNumber(phoneNumber) {
    if (phoneNumber) {
      const cleanNumber = phoneNumber.replace(/\D/g, "");
      const formattedNumber = cleanNumber.replace(
        /(\d{2})(\d{2})(\d{1,5})(\d{1,4})/,
        "+$1 ($2) $3-$4"
      );
      return formattedNumber;
    }
    return "";
  }

  const renderActionButtons = (whatsApp) => {
    return (
      <>
        {whatsApp.status === "qrcode" && (
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={() => handleOpenQrModal(whatsApp)}
          >
            {i18n.t("connections.buttons.qrcode")}
          </Button>
        )}

        {whatsApp.status === "DISCONNECTED" && (
          <>
            {!whatsApp.zapi && (
              <Button
                size="small"
                variant="outlined"
                color="primary"
                onClick={() => handleStartWhatsAppSession(whatsApp.id)}
              >
                {i18n.t("connections.buttons.tryAgain")}
              </Button>
            )}{" "}
            <Button
              size="small"
              variant="outlined"
              color="secondary"
              onClick={() => handleRequestNewQrCode(whatsApp)}
              disabled={isLoadingQrCode}
            >
              {isLoadingQrCode ? (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              ) : (
                i18n.t("connections.buttons.newQr")
              )}
            </Button>
          </>
        )}

        {(whatsApp.status === "CONNECTED" ||
          whatsApp.status === "PAIRING" ||
          whatsApp.status === "TIMEOUT") && (
          <Button
            size="small"
            variant="outlined"
            color="secondary"
            onClick={() => {
              handleOpenConfirmationModal("disconnect", whatsApp.id);
            }}
            disabled={isLoadingDisconnect}
          >
            {isLoadingDisconnect ? (
              <CircularProgress size={24} className={classes.buttonProgress} />
            ) : (
              i18n.t("connections.buttons.disconnect")
            )}
          </Button>
        )}

        {whatsApp.status === "OPENING" && (
          <Button size="small" variant="outlined" disabled color="default">
            {i18n.t("connections.buttons.connecting")}
          </Button>
        )}
      </>
    );
  };

  const renderStatusToolTips = (whatsApp) => {
    return (
      <div className={classes.customTableCell}>
        {whatsApp.status === "DISCONNECTED" && (
          <CustomToolTip
            title={i18n.t("connections.toolTips.disconnected.title")}
            content={i18n.t("connections.toolTips.disconnected.content")}
          >
            <SignalCellularConnectedNoInternet0Bar color="secondary" />
          </CustomToolTip>
        )}
        {whatsApp.status === "OPENING" && (
          <CircularProgress size={24} className={classes.buttonProgress} />
        )}
        {whatsApp.status === "qrcode" && (
          <CustomToolTip
            title={i18n.t("connections.toolTips.qrcode.title")}
            content={i18n.t("connections.toolTips.qrcode.content")}
          >
            <CropFree />
          </CustomToolTip>
        )}
        {whatsApp.status === "CONNECTED" && (
          <CustomToolTip title={i18n.t("connections.toolTips.connected.title")}>
            <SignalCellular4Bar style={{ color: green[500] }} />
          </CustomToolTip>
        )}
        {(whatsApp.status === "TIMEOUT" || whatsApp.status === "PAIRING") && (
          <CustomToolTip
            title={i18n.t("connections.toolTips.timeout.title")}
            content={i18n.t("connections.toolTips.timeout.content")}
          >
            <SignalCellularConnectedNoInternet2Bar color="secondary" />
          </CustomToolTip>
        )}
      </div>
    );
  };

  return (
    <MainContainer>
      <ConfirmationModal
        title={confirmModalInfo.title}
        open={confirmModalOpen}
        onClose={setConfirmModalOpen}
        onConfirm={handleSubmitConfirmationModal}
        loadingConfirm={
          isLoadingDisconnect && confirmModalInfo.action === "disconnect"
        }
      >
        {confirmModalInfo.message}
      </ConfirmationModal>

      <NewQrCodeModal
        open={newQrModalOpen}
        onClose={handleCloseQrModal}
        whatsAppId={!whatsAppModalOpen && selectedWhatsApp?.id}
      />

      <QrcodeModal
        open={qrModalOpen}
        onClose={handleCloseQrModal}
        whatsAppId={!whatsAppModalOpen && selectedWhatsApp?.id}
      />

      <WhatsAppModal
        open={whatsAppModalOpen}
        onClose={handleCloseWhatsAppModal}
        whatsAppId={!qrModalOpen && selectedWhatsApp?.id}
        onUpdate={(updatedWhatsApp) => {
          dispatch({ type: "UPDATE_WHATSAPPS", payload: updatedWhatsApp });
        }}
        whatsApps={whatsApps}
      />

      <MainHeader>
        <Title>{i18n.t("connections.title")}</Title>
        <MainHeaderButtonsWrapper>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenWhatsAppModal}
          >
            {i18n.t("connections.buttons.add")}
          </Button>
        </MainHeaderButtonsWrapper>
      </MainHeader>

      <Paper className={classes.mainPaper} variant="outlined">
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">
                {i18n.t("connections.table.name")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("connections.table.status")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("connections.table.session")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("connections.table.lastUpdate")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("connections.table.default")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("connections.table.phoneNumber")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("connections.table.actions")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRowSkeleton columns={7} />
            ) : (
              <>
                {whatsApps?.length > 0 &&
                  whatsApps.map((whatsApp) => {
                    return (
                      <TableRow key={whatsApp.id}>
                        <TableCell align="center">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <span
                              style={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {whatsApp.name}
                            </span>
                          </div>
                        </TableCell>

                        <TableCell align="center">
                          {renderStatusToolTips(whatsApp)}
                        </TableCell>
                        <TableCell align="center">
                          {renderActionButtons(whatsApp)}
                        </TableCell>
                        <TableCell align="center">
                          {whatsApp.updatedAt
                            ? format(
                                parseISO(whatsApp.updatedAt),
                                "dd/MM/yy HH:mm"
                              )
                            : ""}
                        </TableCell>
                        <TableCell align="center">
                          {whatsApp.isDefault && (
                            <div className={classes.customTableCell}>
                              <CheckCircle style={{ color: green[500] }} />
                            </div>
                          )}
                        </TableCell>
                        <TableCell align="center">
                          {formatPhoneNumber(whatsApp.phoneNumber)}
                        </TableCell>
                        <TableCell align="center">
                          <IconButton
                            size="small"
                            onClick={() => handleEditWhatsApp(whatsApp)}
                          >
                            <Edit />
                          </IconButton>

                          {/* <IconButton
                            size="small"
                            onClick={() => {
                              handleOpenConfirmationModal(
                                "delete",
                                whatsApp.id
                              );
                            }}
                          >
                            <DeleteOutline />
                          </IconButton> */}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </>
            )}
          </TableBody>
        </Table>
      </Paper>
    </MainContainer>
  );
};

export default Connections;
