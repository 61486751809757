import { useState, useEffect } from "react";
import toastError from "../../errors/toastError";
import { api } from "../../services/api";

const useTickets = ({
  dashBoard,
  tabOpen,
  searchParam,
  pageNumber,
  status,
  date,
  showAll,
  queueIds,
  withUnreadMessages,
  tags,
  isWithoutQueueSelected
}) => {
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(false);
  const [tickets, setTickets] = useState([]);
  const [count, setCount] = useState(0);

  useEffect(() => {
    setLoading(true);
    let cancelled = false;

    const fetchTickets = async () => {
      try {
        const { data } = await api.get("/tickets/dashboard", {
          params: {
            searchParam,
            pageNumber,
            status,
            date,
            showAll,
            queueIds,
            withUnreadMessages,
            tags,
            isWithoutQueueSelected,
            dashBoard
          }
        });
        if (!cancelled) {
          setTickets(data.tickets);
          setHasMore(data.hasMore);
          setCount(data.count);
        }
      } catch (err) {
        if (!cancelled) {
          toastError(err);
        }
      } finally {
        if (!cancelled) {
          setLoading(false);
        }
      }
    };

    fetchTickets();
    return () => {
      cancelled = true;
    };
  }, [
    searchParam,
    pageNumber,
    status,
    date,
    showAll,
    queueIds,
    withUnreadMessages,
    tags,
    isWithoutQueueSelected,
    tabOpen,
    dashBoard
  ]);

  return { tickets, loading, hasMore, count };
};

export default useTickets;
