import React, { useState, useEffect } from "react";
import openSocket from "socket.io-client";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import { toast } from "react-toastify";
import Tooltip from "@material-ui/core/Tooltip";
import CircularProgress from "@material-ui/core/CircularProgress";

import { api } from "../../services/api";
import { i18n } from "../../translate/i18n.js";
import toastError from "../../errors/toastError";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(8, 8, 3),
  },
  container: {},
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    marginBottom: 12,
  },
  settingOption: {
    marginLeft: "auto",
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

const Settings = () => {
  const classes = useStyles();

  const [settings, setSettings] = useState([]);
  // Estado para controlar quais "keys" estão em processo de atualização
  const [updatingKeys, setUpdatingKeys] = useState([]);

  const fetchSession = async () => {
    try {
      const { data } = await api.get(`/settings`);
      setSettings(data);
    } catch (err) {
      toastError(err);
    }
  };

  useEffect(() => {
    fetchSession();
  }, []);

  useEffect(() => {
    const socket = openSocket(process.env.REACT_APP_BACKEND_URL);

    socket.on("settings", (data) => {
      if (data.action === "update") {
        // Atualiza valor no estado
        setSettings((prevState) => {
          const aux = [...prevState];
          const settingIndex = aux.findIndex((s) => s.key === data.setting.key);
          if (settingIndex !== -1) {
            aux[settingIndex].value = data.setting.value;
          }
          return aux;
        });

        // Remove a "key" de updatingKeys ao receber o broadcast
        setUpdatingKeys((prevKeys) =>
          prevKeys.filter((k) => k !== data.setting.key)
        );
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  // Handler de mudança de Setting
  const handleChangeSetting = async (e) => {
    const selectedValue = e.target.value;
    const settingKey = e.target.name;

    const foundSetting = settings.find((s) => s.key === settingKey);
    if (!foundSetting) {
      toast.error("Configuração não encontrada!");
      return;
    }


    setUpdatingKeys((prev) => [...prev, settingKey]);

    try {
      await api.put(`/settings/${foundSetting.key}`, {
        id: foundSetting.id,
        value: selectedValue,
      });

      toast.success("Configuração atualizada com sucesso!");

      setUpdatingKeys((prev) => prev.filter((k) => k !== settingKey));

      fetchSession();
    } catch (err) {
      toastError(err);
      setUpdatingKeys((prev) => prev.filter((k) => k !== settingKey));
    }
  };

  const getSettingValue = (key) => {
    const setting = settings.find((s) => s.key === key);
    return setting ? setting.value : null;
  };

  return (
    <div className={classes.root}>
      <Container className={classes.container}>
        <Typography variant="body2" gutterBottom>
          {i18n.t("settings.title")}
        </Typography>

        {/* Exemplo de um Setting removido da tela
        <Paper className={classes.paper}>
          <Typography variant="body1">
            {i18n.t("settings.settings.userCreation.name")}
          </Typography>
          <Select
            margin="dense"
            variant="outlined"
            native
            id="userCreation-setting"
            name="userCreation"
            value={getSettingValue("userCreation") || ""}
            className={classes.settingOption}
            onChange={handleChangeSetting}
            disabled={updatingKeys.includes("userCreation")}
          >
            <option value="enabled">
              {i18n.t("settings.settings.userCreation.options.enabled")}
            </option>
            <option value="disabled">
              {i18n.t("settings.settings.userCreation.options.disabled")}
            </option>
          </Select>

          {updatingKeys.includes("userCreation") && (
            <CircularProgress
              size={20}
              style={{ marginLeft: 10 }}
            />
          )}
        </Paper>
        */}

        <Typography variant="body2" gutterBottom></Typography>
        <Tooltip title={i18n.t("settings.settings.timeCreateNewTicket.note")}>
          <Paper className={classes.paper} elevation={3}>
            <Typography variant="body1">
              {i18n.t("settings.settings.timeCreateNewTicket.name")}
            </Typography>
            <Select
              margin="dense"
              variant="outlined"
              native
              id="timeCreateNewTicket-setting"
              name="timeCreateNewTicket"
              value={getSettingValue("timeCreateNewTicket") || ""}
              className={classes.settingOption}
              onChange={handleChangeSetting}
              disabled={updatingKeys.includes("timeCreateNewTicket")}
            >
              <option value="10">
                {i18n.t("settings.settings.timeCreateNewTicket.options.10")}
              </option>
              <option value="30">
                {i18n.t("settings.settings.timeCreateNewTicket.options.30")}
              </option>
              <option value="60">
                {i18n.t("settings.settings.timeCreateNewTicket.options.60")}
              </option>
              <option value="300">
                {i18n.t("settings.settings.timeCreateNewTicket.options.300")}
              </option>
              <option value="1800">
                {i18n.t("settings.settings.timeCreateNewTicket.options.1800")}
              </option>
              <option value="3600">
                {i18n.t("settings.settings.timeCreateNewTicket.options.3600")}
              </option>
              <option value="7200">
                {i18n.t("settings.settings.timeCreateNewTicket.options.7200")}
              </option>
              <option value="21600">
                {i18n.t("settings.settings.timeCreateNewTicket.options.21600")}
              </option>
              <option value="43200">
                {i18n.t("settings.settings.timeCreateNewTicket.options.43200")}
              </option>
              <option value="86400">
                {i18n.t("settings.settings.timeCreateNewTicket.options.86400")}
              </option>
              <option value="172800">
                {i18n.t("settings.settings.timeCreateNewTicket.options.172800")}
              </option>
            </Select>
            {updatingKeys.includes("timeCreateNewTicket") && (
              <CircularProgress size={20} style={{ marginLeft: 10 }} />
            )}
          </Paper>
        </Tooltip>

        <Typography variant="body2" gutterBottom></Typography>
        <Tooltip title={i18n.t("settings.settings.callVoice.note")}>
          <Paper className={classes.paper}>
            <Typography variant="body1">
              {i18n.t("settings.settings.call.name")}
            </Typography>
            <Select
              margin="dense"
              variant="outlined"
              native
              id="call-setting"
              name="call"
              value={getSettingValue("call") || ""}
              className={classes.settingOption}
              onChange={handleChangeSetting}
              disabled={updatingKeys.includes("call")}
            >
              <option value="enabled">
                {i18n.t("settings.settings.call.options.enabled")}
              </option>
              <option value="disabled">
                {i18n.t("settings.settings.call.options.disabled")}
              </option>
            </Select>
            {updatingKeys.includes("call") && (
              <CircularProgress size={20} style={{ marginLeft: 10 }} />
            )}
          </Paper>
        </Tooltip>

        <Tooltip title={i18n.t("settings.settings.CheckMsgIsGroup.note")}>
          <Paper className={classes.paper}>
            <Typography variant="body1">
              {i18n.t("settings.settings.CheckMsgIsGroup.name")}
            </Typography>
            <Select
              margin="dense"
              variant="outlined"
              native
              id="CheckMsgIsGroup-setting"
              name="CheckMsgIsGroup"
              value={getSettingValue("CheckMsgIsGroup") || ""}
              className={classes.settingOption}
              onChange={handleChangeSetting}
              disabled={updatingKeys.includes("CheckMsgIsGroup")}
            >
              <option value="enabled">
                {i18n.t("settings.settings.CheckMsgIsGroup.options.enabled")}
              </option>
              <option value="disabled">
                {i18n.t("settings.settings.CheckMsgIsGroup.options.disabled")}
              </option>
            </Select>
            {updatingKeys.includes("CheckMsgIsGroup") && (
              <CircularProgress size={20} style={{ marginLeft: 10 }} />
            )}
          </Paper>
        </Tooltip>

        {/* 
        Exemplo para ChatBotType (caso queira voltar a usar):
        
        <Paper className={classes.paper}>
          <Typography variant="body1">
            {i18n.t("settings.settings.chatBotType.name")}
          </Typography>
          <Select
            margin="dense"
            variant="outlined"
            native
            id="chatBotType-setting"
            name="chatBotType"
            value={getSettingValue("chatBotType") || ""}
            className={classes.settingOption}
            onChange={handleChangeSetting}
            disabled={updatingKeys.includes("chatBotType")}
          >
            <option value="text">Texto</option>
            <option value="button">Botão</option>
            <option value="list">Lista</option>
          </Select>
          {updatingKeys.includes("chatBotType") && (
            <CircularProgress size={20} style={{ marginLeft: 10 }} />
          )}
        </Paper>
        */}
      </Container>
    </div>
  );
};

export default Settings;
