import React, { useState, useEffect, useContext } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  DialogActions,
  CircularProgress,
  TextField,
  Switch,
  FormControlLabel,
  FormControl,
  FormGroup,
  Tooltip,
  Paper,
  Grid,
  Checkbox,
  FormHelperText,
} from "@material-ui/core";

import { api } from "../../services/api";
import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";
import QueueSelect from "../QueueSelect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCode } from "@fortawesome/free-solid-svg-icons";
import { AuthContext } from "../../context/Auth/AuthContext";

const longText = `
Desmarque esta opção para definir um horário de expediente para os atendimentos.
Quando um usuário escolher ser direcionado a um atendente, o sistema irá
verificar o horário e o dia, se estiver fora do expediente, envia um aviso
ao usuário e não direciona ao atendente escolhido.
`;

const tooltipActiveChatBot = `
Para ativar o Chatbot é necessário adicionar mais de um departamento
`;

const variables = (
  <>
    Nome do Contato: {"{{nome}}"} <br />
    Saudação: {"{{saudacao}}"} <br />
    Hora: {"{{hora}}"}
  </>
);

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },

  multFieldLine: {
    display: "flex",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
  },

  btnWrapper: {
    position: "relative",
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },

  expediente: {
    display: "flex",
    flexWrap: "wrap",
  },
  tituloReceberMsg: {
    fontSize: 12,
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  reabrirTicket: {
    fontSize: 12,
    display: "flex",
    marginLeft: theme.spacing(2),
  },
  textSize: {
    fontSize: 12,
  },
  paperReceberMsg: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  diasSemana: {
    marginLeft: theme.spacing(1),
  },
  ativarChatbot: {
    marginLeft: theme.spacing(1),
  },
  hora: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: 250,
  },
  textoExpediente: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(3),
    width: "100%",
  },
  tooltipVariables: {
    fontSize: "1rem",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontWeight: 400,
    lineHeight: 1.5,
    letterSpacing: "0.00938em",
    cursor: "pointer",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
  },

  tooltipVariablesContainer: {
    margin: "5px",
  },
  iconCoffee: {
    color: "#757fb7",
    marginRight: "5px",
  },
  iconCoffeeContainer: {
    padding: "3px",
  },
}));

const SessionSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),

  defaultDepartment: Yup.number()
    .nullable()
    .required("Selecione um departamento padrão "),
});

const WhatsAppModal = ({
  open,
  onClose,
  whatsAppId,
  onUpdate,
  whatsApps,
}) => {
  const classes = useStyles();

  const initialState = {
    name: "",
    defaultDepartment: "",
    greetingMessage: "",
    farewellMessage: "",
    isDefault: false,
    isMultidevice: true,
  };

  const { user } = useContext(AuthContext);

  // Estados principais do formulário
  const [whatsApp, setWhatsApp] = useState(initialState);
  const [selectedQueueIds, setSelectedQueueIds] = useState([]);
  const [defaultDepartment, setDefaultDepartment] = useState();
  const [defineWorkHours, setDefineWorkHours] = useState(false);
  const [activateChatbot, setActivateChatbot] = useState(false);
  const [activeCheckbox, setActiveCheckbox] = useState(false);
  const [outOfWorkMessage, setOutOfWorkMessage] = useState("");
  const [startWorkHour, setStartWorkHour] = useState("08:00");
  const [endWorkHour, setEndWorkHour] = useState("17:30");
  const [startWorkHourWeekend, setStartWorkHourWeekend] = useState("08:00");
  const [endWorkHourWeekend, setEndWorkHourWeekend] = useState("17:30");

  // Dias da semana
  const [seg, setSeg] = useState(true);
  const [ter, setTer] = useState(true);
  const [quar, setQuar] = useState(true);
  const [quin, setQuin] = useState(true);
  const [sex, setSex] = useState(true);
  const [sab, setSab] = useState(false);
  const [dom, setDom] = useState(false);

  // Carrega dados de um WhatsApp existente ou limpa todos os campos se for novo
  useEffect(() => {
    const fetchSession = async () => {
      if (whatsAppId) {
        // Edição
        try {
          const { data } = await api.get(`whatsapp/${whatsAppId}`);
          setWhatsApp(data);
          setSeg(data.monday);
          setTer(data.tuesday);
          setQuar(data.wednesday);
          setQuin(data.thursday);
          setSex(data.friday);
          setSab(data.saturday);
          setDom(data.sunday);
          setActivateChatbot(data.hasChatbot);
          setDefineWorkHours(data.defineWorkHours);
          setOutOfWorkMessage(data.outOfWorkMessage || "");
          setStartWorkHour(data.startWorkHour || "08:00");
          setEndWorkHour(data.endWorkHour || "17:30");
          setStartWorkHourWeekend(data.startWorkHourWeekend || "08:00");
          setEndWorkHourWeekend(data.endWorkHourWeekend || "17:30");

          const whatsQueueIds = data.queues?.map((queue) => queue.id);
          setSelectedQueueIds(whatsQueueIds || []);
          setDefaultDepartment(data.defaultDepartment);
          handleActivateCheckBox(whatsQueueIds || []);
        } catch (err) {
          toastError(err);
        }
      } else {
        // Criação: reseta tudo
        setWhatsApp(initialState);
        setSelectedQueueIds([]);
        setDefaultDepartment(null);
        setDefineWorkHours(false);
        setActivateChatbot(false);
        setActiveCheckbox(false);
        setOutOfWorkMessage("");
        setStartWorkHour("08:00");
        setEndWorkHour("17:30");
        setStartWorkHourWeekend("08:00");
        setEndWorkHourWeekend("17:30");
        setSeg(true);
        setTer(true);
        setQuar(true);
        setQuin(true);
        setSex(true);
        setSab(false);
        setDom(false);
      }
    };

    if (open) {
      fetchSession();
    }
  }, [whatsAppId, open]); 

  const handleActivateChatbot = (e) => {
    if (e.target.value === "activeChatbot") {
      setActivateChatbot(e.target.checked);
    }
  };

  const handleActivateCheckBox = (queues) => {
    // Se tiver apenas 1 departamento, desativa Chatbot
    if (queues.length > 1) {
      setActiveCheckbox(false);
    } else {
      setActiveCheckbox(true);
      setActivateChatbot(false);
    }
  };

  const handleChange = (e) => {
    if (e.target.value === "MON") {
      setSeg(e.target.checked);
    }
    if (e.target.value === "TUE") {
      setTer(e.target.checked);
    }
    if (e.target.value === "WED") {
      setQuar(e.target.checked);
    }
    if (e.target.value === "THU") {
      setQuin(e.target.checked);
    }
    if (e.target.value === "FRI") {
      setSex(e.target.checked);
    }
    if (e.target.value === "SAT") {
      setSab(e.target.checked);
    }
    if (e.target.value === "SUN") {
      setDom(e.target.checked);
    }

    if (e.target.value === "defineWorkHours") {
      setDefineWorkHours(e.target.checked);
    }
  };

  const handleSaveWhatsApp = async (values) => {
    const farewellMessage = values.farewellMessage
      ? values.farewellMessage.trim()
      : null;

    const whatsappData = {
      ...values,
      farewellMessage: farewellMessage === "" ? null : farewellMessage,
      queueIds: selectedQueueIds,
      startWorkHour,
      endWorkHour,
      defineWorkHours,
      outOfWorkMessage,
      startWorkHourWeekend,
      endWorkHourWeekend,
      hasChatbot: activateChatbot,
      monday: seg,
      tuesday: ter,
      wednesday: quar,
      thursday: quin,
      friday: sex,
      saturday: sab,
      sunday: dom,
    };

    try {
      if (whatsAppId) {
        // Edição
        const { data } = await api.put(`/whatsapp/${whatsAppId}`, whatsappData);
        onUpdate && onUpdate(data);
      } else {
        // Criação
        const response = await api.post(`/whatsapp`, whatsappData);
        onUpdate && onUpdate(response.data.whatsapp);
      }
      handleClose();
    } catch (err) {
      toastError(err);
    }
  };

  const handleClose = () => {
    onClose();
    // Ao fechar, limpamos novamente os dados:
    setWhatsApp(initialState);
    setSelectedQueueIds([]);
    setDefaultDepartment(null);
    setDefineWorkHours(false);
    setActivateChatbot(false);
    setActiveCheckbox(false);
    setOutOfWorkMessage("");
    setStartWorkHour("08:00");
    setEndWorkHour("17:30");
    setStartWorkHourWeekend("08:00");
    setEndWorkHourWeekend("17:30");
    setSeg(true);
    setTer(true);
    setQuar(true);
    setQuin(true);
    setSex(true);
    setSab(false);
    setDom(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      scroll="paper"
    >
      <DialogTitle>
        {whatsAppId
          ? i18n.t("whatsappModal.title.edit")
          : i18n.t("whatsappModal.title.add")}
      </DialogTitle>
      <Formik
        initialValues={whatsApp}
        enableReinitialize={true}
        validationSchema={SessionSchema}
        validate={(values) => {
          const errors = {};
          let otherWhatsApps = whatsApps;

          if (whatsAppId) {
            otherWhatsApps = whatsApps.filter((w) => w.id !== whatsAppId);
          }

          // Garante que exista pelo menos um WhatsApp padrão
          const hasDefaultWhatsApp = otherWhatsApps.some((w) => w.isDefault);
          if (!hasDefaultWhatsApp && !values.isDefault) {
            errors.isDefault =
              "É necessário ter pelo menos um WhatsApp padrão";
          }

          return errors;
        }}
        onSubmit={(values, actions) => {
          setTimeout(() => {
            handleSaveWhatsApp(values);
            actions.setSubmitting(false);
          }, 400);
        }}
      >
        {({ values, touched, errors, isSubmitting, setFieldValue }) => (
          <Form>
            <DialogContent dividers>
              <div className={classes.multFieldLine}>
                <Field
                  as={TextField}
                  label={i18n.t("whatsappModal.form.name")}
                  autoFocus
                  name="name"
                  error={touched.name && Boolean(errors.name)}
                  helperText={touched.name && errors.name}
                  variant="outlined"
                  margin="dense"
                />
                <FormControl
                  error={touched.isDefault && Boolean(errors.isDefault)}
                  component="fieldset"
                >
                  <FormControlLabel
                    control={
                      <Field
                        as={Switch}
                        color="primary"
                        name="isDefault"
                        checked={values.isDefault}
                      />
                    }
                    label={i18n.t("whatsappModal.form.default")}
                  />
                  {touched.isDefault && errors.isDefault && (
                    <FormHelperText>{errors.isDefault}</FormHelperText>
                  )}
                </FormControl>
              </div>

              <div className={classes.tooltipVariablesContainer}>
                <Tooltip
                  title={variables}
                  placement="right"
                  className={classes.tooltipVariables}
                >
                  <span className={classes.iconCoffeeContainer}>
                    <FontAwesomeIcon
                      icon={faCode}
                      className={classes.iconCoffee}
                    />{" "}
                    Variáveis
                  </span>
                </Tooltip>
              </div>

              <div>
                <Field
                  as={TextField}
                  label={i18n.t("queueModal.form.greetingMessage")}
                  type="greetingMessage"
                  multiline
                  rows={5}
                  fullWidth
                  name="greetingMessage"
                  error={
                    touched.greetingMessage && Boolean(errors.greetingMessage)
                  }
                  helperText={
                    touched.greetingMessage && errors.greetingMessage
                  }
                  variant="outlined"
                  margin="dense"
                />
              </div>

              <div>
                <Field
                  as={TextField}
                  label={i18n.t("whatsappModal.form.farewellMessage")}
                  type="farewellMessage"
                  multiline
                  rows={5}
                  fullWidth
                  name="farewellMessage"
                  error={
                    touched.farewellMessage && Boolean(errors.farewellMessage)
                  }
                  helperText={
                    touched.farewellMessage && errors.farewellMessage
                  }
                  variant="outlined"
                  margin="dense"
                />
              </div>

              {/* CONFIGURAÇÕES DE HORÁRIO DE EXPEDIENTE */}
              {defineWorkHours && (
                <TextField
                  label={i18n.t("queueModal.form.outOfWorkMessage")}
                  rows={4}
                  multiline
                  fullWidth
                  name="outOfWorkMessage"
                  value={outOfWorkMessage}
                  error={
                    touched.outOfWorkMessage &&
                    Boolean(errors.outOfWorkMessage)
                  }
                  helperText={touched.outOfWorkMessage && errors.outOfWorkMessage}
                  variant="outlined"
                  margin="dense"
                  onChange={(e) => setOutOfWorkMessage(e.target.value)}
                />
              )}

              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} className={classes.diasSemana}>
                  <FormControl component="fieldset">
                    <FormGroup
                      aria-label="position"
                      row
                      sx={{
                        width: {
                          xs: 100,
                          sm: 200,
                          md: 300,
                          lg: 600,
                          xl: 700,
                        },
                      }}
                    >
                      <Tooltip title={longText} placement="top">
                        <FormControlLabel
                          value="defineWorkHours"
                          control={
                            <Checkbox
                              size="small"
                              checked={defineWorkHours}
                              onChange={handleChange}
                            />
                          }
                          label="Definir horário de expediente"
                          labelPlacement="end"
                        />
                      </Tooltip>
                    </FormGroup>
                  </FormControl>
                </Grid>
                {defineWorkHours && (
                  <>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        className={classes.hora}
                        type="time"
                        label={i18n.t("queueModal.form.startWorkHour")}
                        name="startWorkHour"
                        value={startWorkHour}
                        onChange={(e) => setStartWorkHour(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        className={classes.hora}
                        type="time"
                        label={i18n.t("queueModal.form.endWorkHour")}
                        name="endWorkHour"
                        value={endWorkHour}
                        onChange={(e) => setEndWorkHour(e.target.value)}
                      />
                    </Grid>
                  </>
                )}
              </Grid>

              {defineWorkHours && (
                <>
                  <Grid item xs={12} sm={12} className={classes.diasSemana}>
                    <FormControl component="fieldset">
                      <FormGroup
                        aria-label="position"
                        row
                        sx={{
                          width: {
                            xs: 100,
                            sm: 200,
                            md: 300,
                            lg: 600,
                            xl: 700,
                          },
                        }}
                      >
                        <FormControlLabel
                          value="MON"
                          control={
                            <Checkbox
                              size="small"
                              checked={seg}
                              onChange={handleChange}
                            />
                          }
                          label={i18n.t("queueModal.form.monday")}
                          labelPlacement="end"
                        />
                        <FormControlLabel
                          value="TUE"
                          control={
                            <Checkbox
                              size="small"
                              checked={ter}
                              onChange={handleChange}
                            />
                          }
                          label={i18n.t("queueModal.form.tuesday")}
                          labelPlacement="end"
                        />
                        <FormControlLabel
                          value="WED"
                          control={
                            <Checkbox
                              size="small"
                              checked={quar}
                              onChange={handleChange}
                            />
                          }
                          label={i18n.t("queueModal.form.wednesday")}
                          labelPlacement="end"
                        />
                        <FormControlLabel
                          value="THU"
                          control={
                            <Checkbox
                              size="small"
                              checked={quin}
                              onChange={handleChange}
                            />
                          }
                          label={i18n.t("queueModal.form.thursday")}
                          labelPlacement="end"
                        />
                        <FormControlLabel
                          value="FRI"
                          control={
                            <Checkbox
                              size="small"
                              checked={sex}
                              onChange={handleChange}
                            />
                          }
                          label={i18n.t("queueModal.form.friday")}
                          labelPlacement="end"
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>
                </>
              )}

              {defineWorkHours && (
                <>
                  <Paper style={{ marginTop: 10 }}>
                    <Grid container spacing={2} style={{ marginTop: 10 }}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          className={classes.hora}
                          type="time"
                          label={i18n.t("queueModal.form.startWorkHour")}
                          name="StartWorkHourWeekend"
                          value={startWorkHourWeekend}
                          onChange={(e) =>
                            setStartWorkHourWeekend(e.target.value)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          className={classes.hora}
                          type="time"
                          label={i18n.t("queueModal.form.endWorkHour")}
                          name="EndWorkHourWeekend"
                          value={endWorkHourWeekend}
                          onChange={(e) =>
                            setEndWorkHourWeekend(e.target.value)
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} className={classes.diasSemana}>
                      <FormControl component="fieldset">
                        <FormGroup
                          aria-label="position"
                          row
                          sx={{
                            width: {
                              xs: 100,
                              sm: 200,
                              md: 300,
                              lg: 600,
                              xl: 700,
                            },
                          }}
                        >
                          <FormControlLabel
                            value="SAT"
                            control={
                              <Checkbox
                                size="small"
                                checked={sab}
                                onChange={handleChange}
                              />
                            }
                            label={i18n.t("queueModal.form.saturday")}
                            labelPlacement="end"
                          />
                          <FormControlLabel
                            value="SUN"
                            control={
                              <Checkbox
                                size="small"
                                checked={dom}
                                onChange={handleChange}
                              />
                            }
                            label={i18n.t("queueModal.form.sunday")}
                            labelPlacement="end"
                          />
                        </FormGroup>
                      </FormControl>
                    </Grid>
                  </Paper>
                </>
              )}

              <Grid item xs={12} sm={12} className={classes.ativarChatbot}>
                <FormControl component="fieldset">
                  <FormGroup
                    aria-label="position"
                    row
                    sx={{
                      width: {
                        xs: 100,
                        sm: 200,
                        md: 300,
                        lg: 600,
                        xl: 700,
                      },
                    }}
                  >
                    <Tooltip title={tooltipActiveChatBot} placement="top">
                      <FormControlLabel
                        value="activeChatbot"
                        control={
                          <Checkbox
                            size="small"
                            disabled={activeCheckbox}
                            checked={activateChatbot}
                            onChange={handleActivateChatbot}
                          />
                        }
                        label="Ativar Chatbot"
                        labelPlacement="end"
                      />
                    </Tooltip>
                  </FormGroup>
                </FormControl>
              </Grid>

              <QueueSelect
                selectedQueueIds={selectedQueueIds}
                onChange={(selectedIds) => {
                  setSelectedQueueIds(selectedIds);
                  handleActivateCheckBox(selectedIds);
                  setFieldValue("defaultDepartment", null);
                  setDefaultDepartment();
                }}
              />
              <QueueSelect
                selectedQueueIds={selectedQueueIds}
                selectedQueueIdDefault={defaultDepartment}
                multiple={false}
                onChange={(queues) => {
                  setFieldValue("defaultDepartment", queues);
                  setDefaultDepartment(queues);
                }}
                error={
                  touched.defaultDepartment &&
                  Boolean(errors.defaultDepartment)
                }
                helperText={
                  touched.defaultDepartment && errors.defaultDepartment
                }
              />
            </DialogContent>

            <DialogActions>
              <Button
                onClick={handleClose}
                color="secondary"
                disabled={isSubmitting}
                variant="outlined"
              >
                {i18n.t("whatsappModal.buttons.cancel")}
              </Button>
              <Button
                type="submit"
                color="primary"
                disabled={isSubmitting}
                variant="contained"
                className={classes.btnWrapper}
              >
                {whatsAppId
                  ? i18n.t("whatsappModal.buttons.okEdit")
                  : i18n.t("whatsappModal.buttons.okAdd")}
                {isSubmitting && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default React.memo(WhatsAppModal);
