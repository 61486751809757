import React, { useMemo } from "react";
import Markdown from "markdown-to-jsx";
import { Button, CircularProgress } from "@material-ui/core";
import { GetApp } from "@material-ui/icons";
import otherStyle from "../OtherCors/css/otherStyle";
import { api } from "../../services/api";

const elements = [
  "a",
  "abbr",
  "address",
  "area",
  "article",
  "aside",
  "audio",
  "b",
  "base",
  "bdi",
  "bdo",
  "big",
  "blockquote",
  "body",
  "br",
  "button",
  "canvas",
  "caption",
  "cite",
  "code",
  "col",
  "colgroup",
  "data",
  "datalist",
  "dd",
  "del",
  "details",
  "dfn",
  "dialog",
  "div",
  "dl",
  "dt",
  "em",
  "embed",
  "fieldset",
  "figcaption",
  "figure",
  "footer",
  "form",
  "h1",
  "h2",
  "h3",
  "h4",
  "h5",
  "h6",
  "head",
  "header",
  "hgroup",
  "hr",
  "html",
  "i",
  "iframe",
  "img",
  "input",
  "ins",
  "kbd",
  "keygen",
  "label",
  "legend",
  "li",
  "link",
  "main",
  "map",
  "mark",
  "marquee",
  "menu",
  "menuitem",
  "meta",
  "meter",
  "nav",
  "noscript",
  "object",
  "ol",
  "optgroup",
  "option",
  "output",
  "p",
  "param",
  "picture",
  "pre",
  "progress",
  "q",
  "rp",
  "rt",
  "ruby",
  "s",
  "samp",
  "script",
  "section",
  "select",
  "small",
  "source",
  "span",
  "strong",
  "style",
  "sub",
  "summary",
  "sup",
  "table",
  "tbody",
  "td",
  "textarea",
  "tfoot",
  "th",
  "thead",
  "time",
  "title",
  "tr",
  "track",
  "u",
  "ul",
  "var",
  "video",
  "wbr",
  // SVG
  "circle",
  "clipPath",
  "defs",
  "ellipse",
  "foreignObject",
  "g",
  "image",
  "line",
  "linearGradient",
  "marker",
  "mask",
  "path",
  "pattern",
  "polygon",
  "polyline",
  "radialGradient",
  "rect",
  "stop",
  "svg",
  "text",
  "tspan",
];

const allowedElements = ["a", "b", "strong", "em", "u", "code", "del"];


const CustomLink = ({ children, ...props }) => (
  <a {...props} target="_blank" rel="noopener noreferrer">
    {children}
  </a>
);

const MarkdownWrapper = ({ children, isEdited, message, mediaUrl }) => {
  const otherClass = otherStyle();


  const options = useMemo(() => {
    const markdownOptions = {
      disableParsingRawHTML: true,
      forceInline: true,
      overrides: {
        a: { component: CustomLink },
      },
    };
    elements.forEach((element) => {
      if (!allowedElements.includes(element)) {
        markdownOptions.overrides[element] = (el) => el.children || null;
      }
    });
    return markdownOptions;
  }, []);


  if (children?.includes("BEGIN:VCARD")) children = null;
  if (children?.includes("data:image/")) children = null;
  if (!children) return null;


  const isMediaMessage = [
    "image",
    "video",
    "audio",
    "document",
    "liveLocationMessage",
    "locationMessage",
  ].includes(message?.mediaType);


  if (!isMediaMessage) {

    const boldRegex = /\*(.*?)\*/g;
    const tildaRegex = /~(.*?)~/g;
    children = children.replace(boldRegex, "**$1**");
    children = children.replace(tildaRegex, "~~$1~~");

    const combinedRegex =
      /(\[([^\]]+)\]\((https?:\/\/[^\s]+)\))|(https?:\/\/[^\s]+)/g;
    children = children.replace(
      combinedRegex,
      (match, fullMarkdownLink, linkText, linkUrl, rawUrl) => {
        if (fullMarkdownLink) return match;
        let trimmedUrl = rawUrl.replace(/^(\*|_|\(|\)|`|'|~)+/g, "");
        trimmedUrl = trimmedUrl.replace(/(\*|_|\(|\)|`|'|~)+$|[.,!?]+$/g, "");
        return `[${trimmedUrl}](${trimmedUrl})`;
      }
    );


    const domainRegex = /(?<=^|\s)(?!\[)([^\s]+\.(?:com(\.br)?|br)(?:\/[^\s]*)?)(?=$|\s)/gi;
    children = children.replace(domainRegex, (domain) => {
 
      if (domain.includes("://")) return domain;

      if (/^\[.*\]\(.*\)$/.test(domain)) return domain;
  
      const prefix =
        domain.startsWith("http://") || domain.startsWith("https://")
          ? ""
          : "http://";
      return `[${domain}](${prefix}${domain})`;
    });
  }

  const showDownloadButton =
    message?.mediaType &&
    ![
      "image",
      "video",
      "audio",
      "liveLocationMessage",
      "locationMessage",
      "extendedTextMessage",
      "conversation",
    ].includes(message?.mediaType);

  const isPending = mediaUrl && mediaUrl.endsWith("PENDING");

  const handleDownload = async (e) => {
    e.preventDefault();
    try {
      const path = mediaUrl.split("/");
      const response = await api.get(
        `/messages/stream-file/${message?.idGraf}/${path[3]}`,
        { responseType: "blob" }
      );
      const fileBlob = new Blob([response.data], { type: message?.mediaType });
      const downloadUrl = URL.createObjectURL(fileBlob);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.download = message?.mediaNameOriginal;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      console.error("Erro ao baixar o arquivo:", error);
    }
  };

  const style = {
    marginBottom: isEdited ? "10px" : "5px",
    ...(isEdited || !message?.mediaUrl ? { marginRight: "30px" } : {}),
  };

  return (
    <div style={style}>
      {/* Se não for localização, renderiza Markdown */}
      {!(
        message?.mediaType === "locationMessage" ||
        message?.mediaType === "liveLocationMessage" ||
        message?.dataJson?.message?.locationMessage ||
        message?.dataJson?.message?.liveLocationMessage
      ) && <Markdown options={options}>{children}</Markdown>}

      {/* Exibe botão de download se aplicável */}
      {showDownloadButton && (
        <div style={{ overflow: "hidden" }}>
          <div style={{ marginRight: "-17px" }}></div>
          <div className={otherClass.containerDownloadButton}>
            <Button
              startIcon={
                isPending ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <GetApp />
                )
              }
              color="primary"
              variant="outlined"
              target="_blank"
              disabled={isPending}
              onClick={handleDownload}
            >
              {isPending ? "" : "Download"}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default MarkdownWrapper;
