import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Chip from "@material-ui/core/Chip";
import toastError from "../../errors/toastError";
import { api } from "../../services/api";
import { i18n } from "../../translate/i18n";
import { FormHelperText } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
}));

const QueueSelect = ({ selectedQueueIds, selectedQueueIdDefault, onChange, multiple = true, error, helperText }) => {
  const classes = useStyles();
  const [queues, setQueues] = useState([]);
  const [queuesDefault, setQueuesDefault] = useState([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get("/queue");
        setQueues(data);
        setLoaded(true);
      } catch (err) {
        toastError(err);
      }
    })();
  }, []);

  useEffect(() => {
    if (!multiple && selectedQueueIds.length > 0 && queues.length > 0) {
      const dataDefault = selectedQueueIds.map((queueId) => queues.find(queue => queue.id === queueId)).filter(Boolean);
      setQueuesDefault(dataDefault);
    }
  }, [multiple, queues, selectedQueueIds]);

  const handleChange = e => {
    onChange(e.target.value);
  };

  if (!loaded) {
    return <div>Loading...</div>;
  }

  return (
    <div style={{ marginTop: 6 }}>
      <FormControl fullWidth margin="dense" variant="outlined" error={error}>
        <InputLabel>{multiple ? i18n.t("queueSelect.inputLabel") : 'Departamento Padrão'}</InputLabel>
        <Select
          multiple={multiple}
          labelWidth={multiple ? 115 : 160}
          value={multiple ? selectedQueueIds : selectedQueueIdDefault}
          onChange={handleChange}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            getContentAnchorEl: null,
          }}
          renderValue={selected => (
            <div className={classes.chips}>
              {multiple ? (
                selected
                  .map(id => queues.find(q => q.id === id))
                  .filter(queue => queue)
                  .map(queue => (
                    <Chip
                      key={queue.id}
                      style={{ backgroundColor: queue.color }}
                      variant="outlined"
                      label={queue.name}
                      className={classes.chip}
                    />
                  ))
              ) : (
                <span style={{ margin: 2 }}>
                  {queues.find(q => q.id === selected)?.name}
                </span>
              )}
            </div>
          )}
        >
          {multiple ? (
            queues.map((queue) => (
              <MenuItem key={queue.id} value={queue.id}>
                {queue.name}
              </MenuItem>
            ))
          ) : (
            queuesDefault.map((queue) => (
              <MenuItem key={queue.id} value={queue.id}>
                {queue.name}
              </MenuItem>
            ))
          )}
        </Select>
        {error && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    </div>
  );
};

export default QueueSelect
