import React, { useState, useEffect, useContext, useRef } from "react";
import "emoji-mart/css/emoji-mart.css";
import { useParams } from "react-router-dom";
import { Picker } from "emoji-mart";
import MicRecorder from "mic-recorder-to-mp3";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green } from "@material-ui/core/colors";
import IconButton from "@material-ui/core/IconButton";
import MoreVert from "@material-ui/icons/MoreVert";
import MoodIcon from "@material-ui/icons/Mood";
import SendIcon from "@material-ui/icons/Send";
import CancelIcon from "@material-ui/icons/Cancel";
import ClearIcon from "@material-ui/icons/Clear";
import MicIcon from "@material-ui/icons/Mic";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import AddBox from "@material-ui/icons/AddBox";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import DescriptionIcon from "@material-ui/icons/Description";
import MovieCreationIcon from "@material-ui/icons/MovieCreation";

import {
  Button,
  FormControlLabel,
  Hidden,
  Menu,
  MenuItem,
  Switch,
  ClickAwayListener,
} from "@material-ui/core";

import { i18n } from "../../translate/i18n";
import { api } from "../../services/api";
import RecordingTimer from "./RecordingTimer";
import { ReplyMessageContext } from "../../context/ReplyingMessage/ReplyingMessageContext";
import { AuthContext } from "../../context/Auth/AuthContext";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import toastError from "../../errors/toastError";
import Icon from "../Icon";
import ScheduleModal from "../ScheduleModal";
import { useIsChatViewRoute } from "../../hooks/useViewRoute";
import { cleanMessageVCard } from "../../utils/cleanMessageVCard";
import { generateRandomString } from "../../utils/generateRandomString";

const Mp3Recorder = new MicRecorder({ bitRate: 128 });

const useStyles = makeStyles((theme) => ({
  mainWrapper: {
    background: "#F0F2F5",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
    [theme.breakpoints.down("sm")]: {
      position: "fixed",
      bottom: 0,
      width: "100%",
    },
  },
  newMessageBox: {
    background: "#F0F2F5",
    width: "100%",
    display: "flex",
    padding: "7px",
    alignItems: "center",
  },
  messageInputWrapper: {
    padding: 6,
    marginRight: 7,
    background: "#fff",
    display: "flex",
    borderRadius: 8,
    flex: 1,
    position: "relative",
  },
  messageInput: {
    paddingLeft: 10,
    flex: 1,
    border: "none",
    overflowY: "auto",
    "& *::-webkit-scrollbar": {
      display: "none",
    },
    "& *": {
      "-ms-overflow-style": "none",
      scrollbarWidth: "none",
    },
  },
  sendMessageIcons: {
    color: "#54656F",
  },
  uploadInput: {
    display: "none",
  },
  viewMediaInputWrapper: {
    display: "flex",
    padding: "10px 13px",
    position: "relative",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#eee",
    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
    flexWrap: "wrap",
  },
  circleLoading: {
    color: green[500],
    opacity: "70%",
    position: "absolute",
    top: "20%",
    left: "50%",
    marginLeft: -12,
  },
  filePreviewContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: "10px",
    flex: 1,
    marginLeft: "20px",
  },
  filePreviewItem: {
    position: "relative",
    width: "80px",
    height: "80px",
  },
  filePreviewImage: {
    width: "80px",
    height: "80px",
    objectFit: "cover",
    borderRadius: 5,
    backgroundColor: "transparent",
    border: "none",
    boxShadow: "none",
  },
  filePreviewRemove: {
    position: "absolute",
    top: 0,
    right: 0,
    backgroundColor: "#fff",
    fontSize: "1rem",
    padding: 2,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  docPreviewWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "80px",
    height: "80px",
    borderRadius: 5,
    backgroundColor: "#f5f5f5",
    position: "relative",
    overflow: "hidden",
    textAlign: "center",
    border: "none",
    boxShadow: "none",
  },
  docIcon: {
    fontSize: "2rem",
  },
  docName: {
    fontSize: "0.75rem",
    color: "#444",
    marginTop: 4,
    wordWrap: "break-word",
    textAlign: "center",
  },
  docExtension: {
    fontSize: "0.7rem",
    color: "#777",
  },
  emojiBox: {
    position: "absolute",
    bottom: 63,
    width: 40,
    borderTop: "1px solid #e8e8e8",
  },
  audioLoading: {
    color: green[500],
    opacity: "70%",
  },
  recorderWrapper: {
    display: "flex",
    alignItems: "center",
  },
  cancelAudioIcon: {
    color: "red",
  },
  sendAudioIcon: {
    color: "green",
  },
  replyginMsgWrapper: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: 8,
    paddingLeft: 73,
    paddingRight: 7,
  },
  replyginMsgContainer: {
    flex: 1,
    marginRight: 5,
    overflowY: "hidden",
    backgroundColor: "rgba(0, 0, 0, 0.05)",
    borderRadius: 7.5,
    display: "flex",
    position: "relative",
  },
  replyginMsgBody: {
    padding: 10,
    display: "block",
    whiteSpace: "pre-wrap",
    overflow: "hidden",
  },
  replyginContactMsgSideColor: {
    flex: "none",
    width: "4px",
    backgroundColor: "#35cd96",
  },
  replyginSelfMsgSideColor: {
    flex: "none",
    width: "4px",
    backgroundColor: "#6bcbef",
  },
  messageContactName: {
    display: "flex",
    color: "#6bcbef",
    fontWeight: 500,
  },
  messageQuickAnswersWrapper: {
    margin: 0,
    position: "absolute",
    bottom: "50px",
    background: "#ffffff",
    padding: "2px",
    border: "1px solid #CCC",
    left: 0,
    width: "100%",
    "& li": {
      listStyle: "none",
      "& a": {
        display: "block",
        padding: 8,
        "&:hover": {
          background: "#F1F1F1",
          cursor: "pointer",
        },
      },
    },
  },
  buttonLinkProposal: {
    backgroundColor: "#2196F330",
    color: "#2196F3",
    border: "1px solid #2196F3",
    height: "29px",
    margin: "2px 15px 0px",
  },
}));

function getIconAndColor(isVideo, extension) {
  if (isVideo) {
    return { IconComponent: MovieCreationIcon, color: "#2196F3" };
  }
  let color;
  switch (extension) {
    case "pdf":
      color = "#E53935";
      break;
    case "xls":
    case "xlsx":
      color = "#25D366";
      break;
    case "doc":
    case "docx":
      color = "#34B7F1";
      break;
    case "cdr":
      color = "#AA00FF";
      break;
    case "txt":
      color = "#FB8C00";
      break;
    default:
      color = "#777";
  }
  return { IconComponent: DescriptionIcon, color };
}

const MessageInput = ({
  ticketStatus,
  messageStatus = false,
  onResetMessageStatus,
  droppedFiles = [],
  onClearDroppedFiles = () => {},
}) => {
  const classes = useStyles();
  const { ticketId, paramEstimate } = useParams();
  const { user } = useContext(AuthContext);
  const { setReplyingMessage, replyingMessage } = useContext(ReplyMessageContext);

  const [signMessage, setSignMessage] = useLocalStorage("signOption", true);
  const [medias, setMedias] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const [showEmoji, setShowEmoji] = useState(false);
  const [loading, setLoading] = useState(false);
  const [recording, setRecording] = useState(false);
  const [quickAnswers, setQuickAnswer] = useState([]);
  const [typeBar, setTypeBar] = useState(false);

  const inputRef = useRef();
  const [anchorElMenu, setAnchorElMenu] = useState(null);

  const [scheduleModalOpen, setScheduleModalOpen] = useState(false);
  const handleOpenScheduleModal = () => setScheduleModalOpen(true);
  const handleCloseScheduleModal = () => setScheduleModalOpen(false);

  const isChatViewRoute = useIsChatViewRoute();
  const [shouldShowSecond, setShouldShowSecond] = useState(false);
  const [isSendingMedia, setIsSendingMedia] = useState(false);
  const [isMultiline, setIsMultiline] = useState(true);

  
  useEffect(() => {
    if (droppedFiles.length > 0) {
      setMedias((prev) => {
        const merged = [...prev];
        droppedFiles.forEach((file) => {
          const uniqueId = `${file.name}-${file.size}-${file.lastModified}`;
          if (!merged.some((m) => `${m.name}-${m.size}-${m.lastModified}` === uniqueId)) {
            merged.push(file);
          }
        });
        return merged;
      });
    }
  }, [droppedFiles]);

  // Se está na tela de chat, força signMessage true.
  useEffect(() => {
    if (isChatViewRoute) {
      setSignMessage(true);
    }
  }, [isChatViewRoute, setSignMessage]);

  // Foco ao responder.
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [replyingMessage]);

  // Ao trocar de ticket, limpamos alguns estados.
  useEffect(() => {
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 500);

    return () => {
      setInputMessage("");
      setShowEmoji(false);
      setReplyingMessage(null);
    };
    // eslint-disable-next-line
  }, [ticketId]);

  // Atualiza se houve mensagem enviada (status)
  useEffect(() => {
    setShouldShowSecond(false);
    if (messageStatus) {
      setShouldShowSecond(true);
      onResetMessageStatus();
    }
  }, [messageStatus, onResetMessageStatus]);

  // Decodifica paramEstimate (se existir)
  function decodeProposalDouble(paramValue) {
    if (!paramValue) return "";
    let base64 = paramValue.replace(/-/g, "+").replace(/_/g, "/");
    while (base64.length % 4 !== 0) {
      base64 += "=";
    }
    const urlEncoded = atob(base64);
    return decodeURIComponent(urlEncoded);
  }

  // Botão que insere o texto decodificado no input
  const hanldeSetMessageLinkToPoposal = () => {
    const textDecoded = decodeProposalDouble(paramEstimate);
    setIsMultiline(false);
    setInputMessage(textDecoded);
  };

  // Seleciona arquivos pelo input
  const handleChangeMedias = (e) => {
    if (!e || !e.target || !e.target.files) return;

    const selectedFiles = Array.from(e.target.files);

    setMedias((prev) => {
      const newFiles = [];
      for (const file of selectedFiles) {
        const uniqueId = `${file.name}-${file.size}-${file.lastModified}`;
        if (!prev.some((m) => `${m.name}-${m.size}-${m.lastModified}` === uniqueId)) {
          newFiles.push(file);
        }
      }
      const merged = [...prev, ...newFiles];
      const totalSize = merged.reduce((acc, file) => acc + file.size, 0);

      if (totalSize > 100 * 1024 * 1024) {
        toastError("Os arquivos excedem o limite total de 100MB.");
        return prev;
      }
      return merged;
    });

    // Limpa o input para permitir anexar o mesmo arquivo novamente
    if (e.target) {
      e.target.value = "";
    }
  };

  // Remove um único arquivo da lista local e do droppedFiles
  const removeFile = (index) => {
    setMedias((prev) => prev.filter((_, i) => i !== index));

    // Também removendo do array "droppedFiles" do componente pai,
    // chamando a função de callback que ele fornece (onClearDroppedFiles).
    if (droppedFiles && droppedFiles.length > 0) {
      const newDroppedFiles = droppedFiles.filter((_, i2) => i2 !== index);
      onClearDroppedFiles(newDroppedFiles);
    }
  };

  // Limpa todos os arquivos
  const removeAllFiles = () => {
    setMedias([]);
    // Aqui também "zera" o droppedFiles do componente pai.
    onClearDroppedFiles([]);
  };

  // Faz o upload
  const handleUploadMedia = async (e) => {
    e.preventDefault();
    if (!medias.length || isSendingMedia) return;
    setIsSendingMedia(true);
    setLoading(true);

    try {
      const formData = new FormData();
      formData.append("fromMe", true);

      for (const media of medias) {
        formData.append("medias", media);
        formData.append("body", media.name);
      }
      await api.post(`/messages/${ticketId}`, formData);
    } catch (err) {
      toastError(err);
    } finally {
      setIsSendingMedia(false);
      setLoading(false);
      setMedias([]);
      onClearDroppedFiles([]);
    }
  };

  // Ao colar arquivos no campo de texto
  const handleInputPaste = (e) => {
    if (!e || !e.clipboardData || !e.clipboardData.files[0]) return;

    const pastedFile = e.clipboardData.files[0];
    setMedias((prev) => [...prev, pastedFile]);
  };

  // Respostas rápidas
  const handleLoadQuickAnswer = async (value) => {
    if (!value || !value.startsWith("/")) {
      setTypeBar(false);
      return;
    }
    try {
      const { data } = await api.get("/quickAnswers/", {
        params: { searchParam: value.substring(1) },
      });
      setQuickAnswer(data.quickAnswers);
      setTypeBar(data.quickAnswers.length > 0);
    } catch {
      setTypeBar(false);
    }
  };

  const handleChangeInput = (e) => {
    setIsMultiline(true);
    setInputMessage(e.target.value);
    handleLoadQuickAnswer(e.target.value);
  };

  const handleQuickAnswersClick = (value) => {
    setInputMessage(value);
    setTypeBar(false);
  };

  // Emoji
  const handleAddEmoji = (emojiObj) => {
    setInputMessage((prev) => prev + emojiObj.native);
  };

  // Envia mensagem de texto
  const handleSendMessage = async () => {
    if (inputMessage.trim() === "") return;
    setLoading(true);

    const messageId = generateRandomString(10);
    const message = {
      id: messageId,
      read: 1,
      fromMe: true,
      mediaUrl: "",
      body: signMessage
        ? `*${user?.name}:*\n${inputMessage.trim()}`
        : inputMessage.trim(),
      quotedMsg: replyingMessage,
    };

    const maxRetries = 5;
    let retries = 0;

    while (retries < maxRetries) {
      try {
        await api.post(`/messages/${ticketId}`, message);
        break;
      } catch (err) {
        const isNetworkError =
          err.message && err.message.includes("Network Error");
        if (isNetworkError) {
          retries++;
          if (retries === maxRetries) {
            toastError(
              "Não foi possível conectar ao servidor. O sistema pode estar fora do ar."
            );
          } else {
            await new Promise((resolve) => setTimeout(resolve, 2000));
          }
        } else {
          toastError(err);
          break;
        }
      }
    }

    setLoading(false);
    setInputMessage("");
    setShowEmoji(false);
    setReplyingMessage(null);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  // Áudio
  const handleStartRecording = async () => {
    setLoading(true);
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true });
      await Mp3Recorder.start();
      setRecording(true);
      setLoading(false);
    } catch (err) {
      toastError(err);
      setLoading(false);
    }
  };

  const handleUploadAudio = async () => {
    setLoading(true);
    try {
      const [, blob] = await Mp3Recorder.stop().getMp3();
      if (blob.size < 10000) {
        setLoading(false);
        setRecording(false);
        return;
      }

      const formData = new FormData();
      const filename = `audio-record-site-${Date.now()}.mp3`;
      formData.append("medias", blob, filename);
      formData.append("body", filename);
      formData.append("fromMe", true);

      await api.post(`/messages/${ticketId}`, formData);
    } catch (err) {
      toastError(err);
    }
    setLoading(false);
    setRecording(false);
  };

  const handleCancelAudio = async () => {
    try {
      await Mp3Recorder.stop().getMp3();
      setRecording(false);
    } catch (err) {
      toastError(err);
    }
  };

  // Menu mobile
  const handleOpenMenuClick = (e) => setAnchorElMenu(e.currentTarget);
  const handleMenuItemClick = () => setAnchorElMenu(null);

  // Render da mensagem “reply”
  const renderReplyingMessage = (message) => (
    <div className={classes.replyginMsgWrapper}>
      <div className={classes.replyginMsgContainer}>
        <span
          className={clsx(classes.replyginContactMsgSideColor, {
            [classes.replyginSelfMsgSideColor]: !message.fromMe,
          })}
        />
        <div className={classes.replyginMsgBody}>
          {!message.fromMe && (
            <span className={classes.messageContactName}>
              {message.contact?.name}
            </span>
          )}
          {cleanMessageVCard(message.body)}
        </div>
      </div>
      <IconButton
        aria-label="showRecorder"
        component="span"
        disabled={loading || ticketStatus !== "open"}
        onClick={() => setReplyingMessage(null)}
      >
        <ClearIcon className={classes.sendMessageIcons} />
      </IconButton>
    </div>
  );

  // Trunca nome
  const truncateName = (name) => {
    const maxLen = 8;
    return name.length > maxLen ? `${name.slice(0, maxLen)}...` : name;
  };

  // Se há arquivos selecionados, mostra prévia
  if (medias.length > 0) {
    return (
      <Paper elevation={0} square className={classes.viewMediaInputWrapper}>
        <IconButton onClick={removeAllFiles}>
          <CancelIcon className={classes.sendMessageIcons} />
        </IconButton>

        {loading ? (
          <CircularProgress className={classes.circleLoading} />
        ) : (
          <div className={classes.filePreviewContainer}>
            {medias.map((media, index) => {
              const isImage = media.type.toLowerCase().startsWith("image/");
              const isVideo = media.type.toLowerCase().startsWith("video/");
              const previewUrl = isImage || isVideo ? URL.createObjectURL(media) : null;
              const truncatedFileName = truncateName(media.name);

              let ext = "";
              if (!isImage && !isVideo) {
                const splitted = media.name.split(".");
                ext = splitted[splitted.length - 1]?.toLowerCase() || "";
              }
              const { IconComponent, color } = getIconAndColor(isVideo, ext);

              return (
                <div key={index} className={classes.filePreviewItem}>
                  {isImage ? (
                    <img
                      src={previewUrl}
                      alt=""
                      className={classes.filePreviewImage}
                    />
                  ) : isVideo ? (
                    <div className={classes.docPreviewWrapper}>
                      <MovieCreationIcon
                        className={classes.docIcon}
                        style={{ color: "#2196F3" }}
                      />
                      <span className={classes.docName}>
                        {truncatedFileName}
                      </span>
                      {media.name.includes(".") && (
                        <span className={classes.docExtension}>
                          .{media.name.split(".").pop()}
                        </span>
                      )}
                    </div>
                  ) : (
                    <div className={classes.docPreviewWrapper}>
                      <IconComponent
                        className={classes.docIcon}
                        style={{ color }}
                      />
                      <span className={classes.docName}>
                        {truncatedFileName}
                      </span>
                      {ext && <span className={classes.docExtension}>.{ext}</span>}
                    </div>
                  )}

                  <IconButton
                    className={classes.filePreviewRemove}
                    onClick={() => removeFile(index)}
                    size="small"
                  >
                    <CancelIcon style={{ fontSize: "1rem" }} />
                  </IconButton>
                </div>
              );
            })}

            {/* Botão para adicionar mais arquivos */}
            <div>
              <input
                multiple
                type="file"
                id="upload-more-button"
                className={classes.uploadInput}
                onChange={handleChangeMedias}
                disabled={loading || isSendingMedia}
              />
              <label
                htmlFor="upload-more-button"
                style={{ display: "flex", marginTop: "15px" }}
              >
                <IconButton
                  disabled={loading || isSendingMedia}
                  component="span"
                >
                  <AddBox />
                </IconButton>
              </label>
            </div>
          </div>
        )}

        <IconButton
          onClick={handleUploadMedia}
          disabled={loading || isSendingMedia}
        >
          <SendIcon className={classes.sendMessageIcons} />
        </IconButton>
      </Paper>
    );
  }

  // Se não há arquivos, mostra campo normal
  return (
    <Paper square elevation={0} className={classes.mainWrapper}>
      {replyingMessage && renderReplyingMessage(replyingMessage)}

      <div className={classes.newMessageBox}>
        <Hidden only={["sm", "xs"]}>
          <IconButton
            disabled={loading || recording || ticketStatus !== "open"}
            onClick={() => setShowEmoji((prev) => !prev)}
          >
            <MoodIcon className={classes.sendMessageIcons} />
          </IconButton>
          {showEmoji && (
            <div className={classes.emojiBox}>
              <ClickAwayListener onClickAway={() => setShowEmoji(false)}>
                <Picker
                  perLine={16}
                  showPreview={false}
                  showSkinTones={false}
                  onSelect={handleAddEmoji}
                />
              </ClickAwayListener>
            </div>
          )}

          <input
            multiple
            type="file"
            id="upload-button"
            disabled={loading || recording || ticketStatus !== "open"}
            className={classes.uploadInput}
            onChange={handleChangeMedias}
          />
          <label htmlFor="upload-button">
            <IconButton
              aria-label="upload"
              component="span"
              disabled={loading || recording || ticketStatus !== "open"}
            >
              <Icon type="attach" />
            </IconButton>
          </label>

          <IconButton
            disabled={loading || recording || ticketStatus !== "open"}
            onClick={handleOpenScheduleModal}
          >
            <Icon type="calendar" />
          </IconButton>

          {/* Se NÃO estiver na tela de chat, mostra opção de assinar a mensagem */}
          {!isChatViewRoute && (
            <FormControlLabel
              style={{ marginRight: 7, marginLeft: 0, color: "#54656F" }}
              control={
                <IconButton
                  disabled={loading || recording || ticketStatus !== "open"}
                  onClick={() => setSignMessage(!signMessage)}
                >
                  <Icon type="assign" fill={signMessage ? "#2196F3" : "black"} />
                </IconButton>
              }
            />
          )}
        </Hidden>

        {/* Menu mobile */}
        <Hidden only={["md", "lg", "xl"]}>
          <IconButton onClick={handleOpenMenuClick}>
            <MoreVert />
          </IconButton>
          <Menu
            anchorEl={anchorElMenu}
            keepMounted
            open={Boolean(anchorElMenu)}
            onClose={handleMenuItemClick}
          >
            <MenuItem onClick={handleMenuItemClick}>
              <IconButton
                disabled={loading || recording || ticketStatus !== "open"}
                onClick={() => setShowEmoji((prev) => !prev)}
              >
                <MoodIcon className={classes.sendMessageIcons} />
              </IconButton>
            </MenuItem>
            <MenuItem onClick={handleMenuItemClick}>
              <input
                multiple
                type="file"
                id="upload-button-mobile"
                disabled={loading || recording || ticketStatus !== "open"}
                className={classes.uploadInput}
                onChange={handleChangeMedias}
              />
              <label htmlFor="upload-button-mobile">
                <IconButton
                  aria-label="upload"
                  component="span"
                  disabled={loading || recording || ticketStatus !== "open"}
                >
                  <AttachFileIcon className={classes.sendMessageIcons} />
                </IconButton>
              </label>
            </MenuItem>
            <MenuItem onClick={handleMenuItemClick}>
              <FormControlLabel
                style={{ marginRight: 7, color: "#54656F" }}
                label={i18n.t("messagesInput.signMessage")}
                labelPlacement="start"
                control={
                  <Switch
                    size="small"
                    checked={signMessage}
                    onChange={(e) => setSignMessage(e.target.checked)}
                    name="showAllTickets"
                    color="primary"
                  />
                }
              />
            </MenuItem>
          </Menu>
        </Hidden>

        <div className={classes.messageInputWrapper}>
          <InputBase
            inputRef={inputRef}
            className={classes.messageInput}
            placeholder={
              ticketStatus === "open"
                ? "Digite uma mensagem ou use '/' para respostas rápidas"
                : "Reabra ou aceite essa conversa para enviar uma mensagem."
            }
            maxRows={4}
            multiline={isMultiline}
            value={inputMessage}
            onChange={handleChangeInput}
            disabled={recording || loading || ticketStatus !== "open"}
            onPaste={(e) => {
              if (ticketStatus === "open") {
                handleInputPaste(e);
              }
            }}
            onKeyPress={(e) => {
              if (loading || e.shiftKey) return;
              if (e.key === "Enter") {
                e.preventDefault();
                handleSendMessage();
              }
            }}
          />

          {/* Se paramEstimate existe, botão para inserir texto de orçamento */}
          {isChatViewRoute && paramEstimate && (
            <Button
              onClick={hanldeSetMessageLinkToPoposal}
              className={classes.buttonLinkProposal}
            >
              <Icon type="attachLink" />
              Enviar Orçamento
            </Button>
          )}

          {typeBar && (
            <ul className={classes.messageQuickAnswersWrapper}>
              {quickAnswers.map((qa, i) => (
                <li key={i}>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a onClick={() => handleQuickAnswersClick(qa.message)}>
                    {`${qa.shortcut} - ${qa.message}`}
                  </a>
                </li>
              ))}
            </ul>
          )}
        </div>

        {/* Botão de envio de mensagem / gravação de áudio */}
        {inputMessage ? (
          <IconButton
            aria-label="sendMessage"
            onClick={handleSendMessage}
            disabled={loading}
          >
            <SendIcon className={classes.sendMessageIcons} />
          </IconButton>
        ) : recording ? (
          <div className={classes.recorderWrapper}>
            <IconButton disabled={loading} onClick={handleCancelAudio}>
              <HighlightOffIcon className={classes.cancelAudioIcon} />
            </IconButton>
            {loading ? (
              <CircularProgress className={classes.audioLoading} />
            ) : (
              <RecordingTimer />
            )}
            <IconButton disabled={loading} onClick={handleUploadAudio}>
              <CheckCircleOutlineIcon className={classes.sendAudioIcon} />
            </IconButton>
          </div>
        ) : (
          <IconButton
            disabled={loading || ticketStatus !== "open"}
            onClick={handleStartRecording}
          >
            <MicIcon className={classes.sendMessageIcons} />
          </IconButton>
        )}

        <ScheduleModal
          open={scheduleModalOpen}
          onClose={handleCloseScheduleModal}
          isFromInput={true}
          ticketId={ticketId}
        />
      </div>
    </Paper>
  );
};

export default MessageInput;
