import React from "react";
import "emoji-mart/css/emoji-mart.css";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import SendIcon from "@material-ui/icons/Send";
import Typography from "@material-ui/core/Typography";
import Icon from "../Icon";

const useStyles = makeStyles((theme) => ({
    mainWrapper: {
        background: "#F0F2F5",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        borderTop: "1px solid rgba(0, 0, 0, 0.12)",
        [theme.breakpoints.down("sm")]: {
            position: "fixed",
            bottom: 0,
            width: "100%",
        },
    },
    newMessageBox: {
        background: "#F0F2F5",
        width: "100%",
        display: "flex",
        alignItems: "center",
        padding: "7px",
    },
    selectedMessageInfo: {
        flexGrow: 1,
        display: "flex",
        alignItems: "center",
    },
    closeButton: {
        marginRight: theme.spacing(1),
    },
    forwardButton: {
        marginLeft: theme.spacing(1),
    },
}));

const MessageForwardInput = ({ onClose, onForward, quantityMessageSelected }) => {
    const classes = useStyles();
    return (
        <Paper square elevation={0} className={classes.mainWrapper}>
            <div className={classes.newMessageBox}>
                <IconButton
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
                <div className={classes.selectedMessageInfo}>
                    <Typography variant="body2">
                        {quantityMessageSelected} {quantityMessageSelected === 1 ? "selecionada" : "selecionadas"}
                    </Typography>
                </div>
                <IconButton
                    className={classes.forwardButton}
                    color="primary"
                    onClick={onForward}
                >
                    <Icon type="foward" />
                </IconButton>
            </div>
        </Paper>
    );
};

export default MessageForwardInput;
